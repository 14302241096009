import React, { useEffect, useState, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import {
  Container, Row, Col, Card, CardBody,
  // Button,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  // FormInput
} from "shards-react";
// import Pusher from 'pusher-js'
// import { debounce, filter } from 'lodash'

import axios from '../utils/axios';
import MyPagination from "../components/common/MyPagination";
// import PageTitle from "../components/common/PageTitle";
import { Spinner } from "react-bootstrap";
// import { removeVietnameseTones } from '../utils/cachamchung'

const Congty = () => {
  const unmounted = useRef(false)
  const [list, setList] = useState()
  const [totPages, setTotPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // const [ketqua, setKetqua] = useState()
  // const [timkiem, setTimkiem] = useState('')
  // const [searching, setSearching] = useState(false)

  // useEffect(() => {
  //   const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  //     cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  //     encrypted: true
  //   });
  //   const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
  //   channel.bind('khaibao', data => {
  //     getData()
  //   });
  //   return () => { pusher.disconnect() }
  // }, [])
  useEffect(() => {
    getData(1)
    return () => { unmounted.current = true }
  }, [])
  const getData = page => {
    axios.get(`congty/danhsach?page=${page}`).then(response => {
      if (!unmounted.current) {
        // if (!searching) {
        //   setKetqua(response.data)
        // }
        // setList(response.data)
        setTotPages(response.data.last_page)
        setList(response.data.data)
      }
    })
  }
  const handleChangePage = ele => {
    setCurrentPage(ele)
    getData(ele)
  }

  // const fetchDropdownOptions = (key, list) => {
  //   const kq = filter(list, (item) => item.hoten.toLowerCase().indexOf(key.trim()) > -1 || removeVietnameseTones(item.hoten.toLowerCase()).indexOf(removeVietnameseTones(key.trim())) > -1 || item.sodienthoai.indexOf(key.trim()) > -1 || item.cmnd.indexOf(key.trim()) > -1)
  //   setKetqua(kq)
  // }
  // const debounceDropDown = useCallback(debounce((nextValue, list) => fetchDropdownOptions(nextValue, list), 500), [])
  // const handleTimkiem = e => {
  //   setTimkiem(e.currentTarget.value)
  //   debounceDropDown(e.currentTarget.value.toLowerCase(), list)
  //   if (e.currentTarget.value.trim()) {
  //     setSearching(true)
  //   } else {
  //     setSearching(false)
  //   }
  // }
  return <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      {/* <PageTitle sm="12" title="Danh sách xét nghiệm Công ty" className="text-md-center" /> */}
    </Row>

    <Row>
      <Col className="mb-4 text-right">
        <NavLink to={`them-moi`} className="btn btn-primary">Thêm mới</NavLink>
      </Col>
    </Row>

    {/* <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            <InputGroup seamless>
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">search</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                className="navbar-search border-0 no-shadow"
                placeholder="Search..."
                value={timkiem}
                onChange={handleTimkiem}
                disabled={!list}
              />
            </InputGroup>
          </CardBody>
        </Card>
      </Col>
    </Row> */}

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Tên Công ty
                    </th>
                    <th scope="col" className="border-0">
                      Ngày xét nghiệm
                    </th>
                    <th scope="col" className="border-0">
                      Người liên hệ
                    </th>
                    <th scope="col" className="border-0">
                      Người xét nghiệm
                    </th>
                    <th scope="col" className="border-0">Tình trạng</th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {list ? (list.length ? list.map((item, index) => <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle font-weight-bold text-capitalize"><NavLink to={`/chi-tiet-cong-ty/${item.id}`}><strong>{item.tencongty}</strong></NavLink></td>
                    <td className="align-middle">{dateFormat(item.ngaythuchien, 'dd/mm/yyyy')}</td>
                    <td className="align-middle">{item.lienhe}<br />{item.dienthoai}</td>
                    <td className="align-middle">{item.thuchien}</td>
                    <td className="align-middle">{item.tinhtrang==0?'':(item.tinhtrang==1?'Hoàn thành':'Đã kết sổ')}</td>
                    <td><NavLink to={`/chi-tiet-cong-ty/${item.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                  </tr>) : <tr><td className="text-center" colSpan="9">No records</td></tr>) : <tr><td className="text-center" colSpan="9"><Spinner animation="border" role="status" /></td></tr>}
                </tbody>
                {list && totPages > 1?<tfoot>
                  <tr>
                    <td colSpan="10">
                      <MyPagination  totPages={totPages} currentPage={currentPage} pageClicked={handleChangePage} />
                    </td>
                  </tr>
                </tfoot>:null}
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
};

export default Congty;
