import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
// import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody, Button, Form, FormInput, FormSelect } from "shards-react";
// import Pusher from 'pusher-js'

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { Spinner, Modal } from "react-bootstrap";
import classNames from "classnames";

const DanhsachNguoidung = () => {
  const unmounted = useRef(false)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [error, setError] = useState(null)
  const [dulieu, setDulieu] = useState({
    name: '',
    email: '',
    active: '1',
    role: 'thungan',
    newPassword: '',
    confirmPassword: ''
  })
  const [danhsach, setDanhsach] = useState()
  // useEffect(() => {
  //   const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  //     cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  //     encrypted: true
  //   });
  //   const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
  //   channel.bind('khaibao', data => {
  //     getData()
  //   });
  //   return () => { pusher.disconnect() }
  // }, [])
  useEffect(() => {
    getData()
    return () => { unmounted.current = true }
  }, [])
  const getData = () => {
    axios.get(`danhsachnguoidung`).then(response => {
      if (!unmounted.current) {
        setDanhsach(response.data)
      }
    })
  }
  const handleInputChange = e => {
    setDulieu({ ...dulieu, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      setError(null)
      axios.post(`themnguoidung`, dulieu).then(response => {
        setLoading(false)
        if (response.data) {
          setShow(false)
          setDanhsach([...danhsach, response.data])
        } else {
          setError(response.error)
        }
      }).catch(err => { setError(err.message); setLoading(false) })
    }
  }
  return <React.Fragment>
    <Modal show={show} onHide={e => setShow(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Thêm người dùng</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-4">
          {loading && <div className="loadingBox pt-5"><Spinner animation="border" role="status" /></div>}
          <Row form>
            {/* First Name */}
            <Col md="6" className="form-group">
              <label htmlFor="feFirstName">Name</label>
              <FormInput
                required
                id="feFirstName"
                placeholder="Name"
                name="name"
                value={dulieu.name}
                onChange={handleInputChange}
              />
            </Col>
            {/* Email */}
            <Col md="6" className="form-group">
              <label htmlFor="feEmail">Email</label>
              <FormInput
                required
                type="email"
                id="feEmail"
                placeholder="Email Address"
                name="email"
                value={dulieu.email}
                onChange={handleInputChange}
                autoComplete="email"
              />
            </Col>
            <Col md="6" className="form-group">
              <label>Role</label>
              <FormSelect
                name="role"
                value={dulieu.role}
                onChange={handleInputChange}>
                <option value="thungan">Thu ngân</option>
                <option value="lab">Lab</option>
                <option value="admin">Admin</option>
              </FormSelect>
            </Col>
            <Col md="6" className="form-group">
              <label>Active</label>
              <FormSelect
                name="active"
                value={dulieu.active}
                onChange={handleInputChange}>
                <option value="1">Active</option>
                <option value="0">Disable</option>
              </FormSelect>
            </Col>
            <Col md="6" className="form-group">
              <label>Mật khẩu mới</label>
              <FormInput
                required
                type="password"
                name="newPassword"
                value={dulieu.newPassword}
                onChange={handleInputChange}
              />
            </Col>
            {/* Password */}
            <Col md="6" className="form-group">
              <label>Xác thực lại mật khẩu</label>
              <FormInput
                required
                type="password"
                name="confirmPassword"
                value={dulieu.confirmPassword}
                onChange={handleInputChange}
              />
            </Col>
            {error ? <Col md="12" className="text-danger">{error}</Col> : null}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" theme="accent">Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="12" title="Danh sách người dùng" className="text-md-center" />
      </Row>

      <Row>
        <Col lg="12" className="mb-4 text-right">
          <Button type="button" onClick={e => setShow(true)}>Thêm người dùng</Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody>
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Họ tên
                      </th>
                      <th scope="col" className="border-0">
                        Email
                      </th>
                      <th scope="col" className="border-0">
                        Chức năng
                      </th>
                      <th scope="col" className="border-0">
                        Đang sử dụng
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {danhsach ? (danhsach.length ? danhsach.map((item, index) => <tr key={index}>
                      <td className="align-middle">{index + 1}</td>
                      <td className="align-middle font-weight-bold text-nowrap text-capitalize"><NavLink to={`/chi-tiet-nguoi-dung/${item.id}`}><strong>{item.name}</strong></NavLink></td>
                      <td className="align-middle">{item.email}</td>
                      <td className="align-middle">{item.role}</td>
                      <td className={classNames("align-middle", item.active === 1 ? '' : 'text-danger')}>{item.active === 1 ? 'Active' : 'Disable'}</td>
                      <td width="150"><NavLink to={`/chi-tiet-nguoi-dung/${item.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                    </tr>) : <tr><td className="text-center" colSpan="8">No records</td></tr>) : <tr><td className="text-center" colSpan="8"><Spinner animation="border" role="status" /></td></tr>}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
};

export default DanhsachNguoidung;
