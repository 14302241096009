import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { renderRoutes } from 'react-router-config';

// import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
// import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
// import MainFooter from "../components/layout/MainFooter";

const AuthLayout = ({ children, noNavbar, noFooter, route }) => (
  <Container fluid>
    <Row>
      {/* <MainSidebar /> */}
      <Col
        className="main-content p-0"
        // lg={{ size: 10, offset: 2 }}
        // md={{ size: 9, offset: 3 }}
        sm="12"
        tag="main"
      >
        {/* {!noNavbar && <MainNavbar />} */}
        {renderRoutes(route.routes)}
        {/* {children} */}
        {/* {!noFooter && <MainFooter />} */}
      </Col>
    </Row>
  </Container>
);

AuthLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

AuthLayout.defaultProps = {
  noNavbar: false,
  noFooter: true
};

export default AuthLayout;
