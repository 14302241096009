import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, AuthLayout } from "./layouts";

// Route Views
import Danhsach from "./views/Danhsach";
import DanhsachTmp from "./views/DanhsachLuutam";
import Chitiet from "./views/Chitiet";
import Dangky from "./views/Dangky";
// import Errors from "./views/Errors";
// import ComponentsOverview from "./views/ComponentsOverview";
// import Tables from "./views/Tables";
import Thongke from "./views/Thongke";
import Login from './views/Login';
import DanhsachLaymau from "./views/DanhsachLaymau";
import DanhsachKetqua from "./views/DanhsachKetqua";
import DanhsachChoketqua from "./views/DanhsachChoketqua";
import DanhsachNguoidung from "./views/DanhsachNguoidung";
import DanhsachKhaibao from './views/DanhsachKhaibao';
import Overview from "./views/Overview";
import UserProfileLite from "./views/UserProfileLite";
import ChitietNguoidung from "./views/ChitietNguoidung";
import Congty from "./views/Congty";
import Themmoi from "./views/Themmoi";
import ChitietCongty from './views/ChitietCongty'
import ChitietKhaibao from "./views/ChitietKhaibao";

export default [
  {
    path: '/',
    exact: true,
    // component: () => <Redirect to="/danh-sach-hom-nay" />
    component: Overview
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: Login // lazy(() => import('views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DefaultLayout,
    routes: [
      // {
      //   path: '/overview',
      //   exact: true,
      //   requireLogin: true,
      //   component: ComponentsOverview
      // },
      {
        path: '/dang-ky',
        exact: true,
        requireLogin: true,
        component: Dangky
      },
      {
        path: '/danh-sach-hom-nay',
        exact: true,
        requireLogin: true,
        component: Danhsach
      },
      {
        path: '/danh-sach-luu-tam',
        exact: true,
        requireLogin: true,
        component: DanhsachTmp
      },
      {
        path: '/danh-sach-lay-mau',
        exact: true,
        requireLogin: true,
        component: DanhsachLaymau
      },
      {
        path: '/danh-sach-cho-ket-qua',
        exact: true,
        requireLogin: true,
        component: DanhsachChoketqua
      },
      {
        path: '/xet-nghiem-cong-ty',
        exact: true,
        requireLogin: true,
        component: Congty
      },
      {
        path: "/chi-tiet-cong-ty/:id",
        exact: true,
        requireLogin: true,
        component: ChitietCongty
      },
      {
        path: '/them-moi',
        exact: true,
        requireLogin: true,
        component: Themmoi
      },
      {
        path: '/khai-bao',
        exact: true,
        requireLogin: true,
        component: DanhsachKhaibao
      },
      {
        path: "/chi-tiet-khai-bao/:id",
        exact: true,
        requireLogin: true,
        component: ChitietKhaibao
      },
      {
        path: '/ket-qua',
        exact: true,
        requireLogin: true,
        component: DanhsachKetqua
      },
      {
        path: "/chi-tiet/:id",
        exact: true,
        requireLogin: true,
        component: Chitiet
      },
      {
        path: "/chi-tiet-nguoi-dung/:id",
        exact: true,
        requireLogin: true,
        component: ChitietNguoidung
      },
      {
        path: '/user-profile',
        exact: true,
        requireLogin: true,
        component: UserProfileLite
      },
      {
        path: '/nguoi-dung',
        exact: true,
        requireLogin: true,
        component: DanhsachNguoidung
      },
      {
        path: '/thong-ke',
        exact: true,
        requireLogin: true,
        component: Thongke
      },
      {
        path: '/thong-ke/:dieukien',
        exact: true,
        requireLogin: true,
        component: Thongke
      },
    ]
  },
  // {
  //   path: "/",
  //   exact: true,
  //   layout: DefaultLayout,
  //   // component: () => <Redirect to="/blog-overview" />
  //   // component: () => <Redirect to="/dang-ky" />
  //   component: () => {
  //     const thongtin = JSON.parse(localStorage.getItem('dangky'))
  //     if (thongtin) {
  //       // da dang ky, kiem tra con gia tri khong
  //       if (thongtin.length) {
  //         // kiem tra xem con gia tri khong
  //         return <Redirect to="/danh-sach" />
  //       } else {
  //         return <Redirect to="/dang-ky" />
  //       }
  //     } else {
  //       return <Redirect to="/dang-ky" />
  //     }
  //   }
  // },
  // {
  //   path: "/danh-sach",
  //   layout: DefaultLayout,
  //   component: Danhsach
  // },
  // {
  //   path: "/chi-tiet/:id",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/dang-ky",
  //   exact: true,
  //   requireLogin: true,
  //   layout: DefaultLayout,
  //   component: Dangky
  // },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // }
];
