import React from "react";
import dateFormat from "dateformat";
import { currencyFormat, docso } from "../../utils/cachamchung";

export default class Phieuthu extends React.Component {
    render() {
        const dulieu = this.props.dulieu
        return <div className="d-none d-print-block sheet padding-25mm">
            <table className="table border-0">
                <tbody>
                    <tr>
                        <td className="border-0 align-middle">
                            <img src="https://xetnghiemtaman.com/logo2.png" className="mr-2" style={{ float: 'left', width: 70 }} />
                            <p className="mb-0 font-weight-bold">PHÒNG XÉT NGHIỆM Y KHOA TÂM AN</p>
                            <p>Điện thoại:02593 518 368 - 0919 196 991</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="text-center text-uppercase font-weight-bold mb-0">Biên lai thu phí - phiếu hẹn</p>
            <div className="text-center font-weight-bold">Ngày {dateFormat(dulieu.ngaykhaibao, 'dd')} tháng {dateFormat(dulieu.ngaykhaibao, 'mm')} năm {dateFormat(dulieu.ngaykhaibao, 'yyyy')}</div>
            <p className="text-center font-weight-bold">Mã BN: {dulieu.mamau}</p>
            <div className="d-flex justify-content-between">
                <div>Họ và tên: <strong className="font-weight-bold text-capitalize">{dulieu.hoten}</strong></div>
                <div>Giới tính: <strong className="font-weight-bold">{dulieu.gioitinh == 1 ? 'Nam' : 'Nữ'}</strong></div>
                <div>Tuổi: <strong className="font-weight-bold">{(new Date().getFullYear()) - parseInt(dateFormat(dulieu.ngaysinh, 'yyyy'))}</strong></div>
            </div>
            <p className="mb-0">Địa chỉ: {dulieu.diachi}</p>
            <p>Ngày lấy mẫu: <strong className="font-weight-bold">{dateFormat(dulieu.ngaykhaibao, 'dd/mm/yyyy')}</strong></p>
            <table className="table table-bordered ketqua-table">
                <thead>
                    <tr className="font-weight-bold">
                        <td className="text-center">STT</td>
                        <td className="text-center">DỊCH VỤ</td>
                        <td className="text-center">GIÁ TIỀN</td>
                        <td className="text-center">GIẢM GIÁ</td>
                        <td className="text-center">THÀNH TIỀN</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center">1</td>
                        <td>Test nhanh Kháng nguyên virut SARS-CoV-2</td>
                        <td className="text-right">{currencyFormat(dulieu.giatien)}</td>
                        <td className="text-right">0</td>
                        <td className="text-right">{currencyFormat(dulieu.giatien)}</td>
                    </tr>
                    <tr className="font-weight-bold">
                        <td colSpan="2" className="text-uppercase text-center">tổng cộng</td>
                        <td className="text-right">{currencyFormat(dulieu.giatien)}</td>
                        <td className="text-right">0</td>
                        <td className="text-right">{currencyFormat(dulieu.giatien)}</td>
                    </tr>
                </tbody>
            </table>
            <p className="font-weight-bold mb-0">TỔNG THANH TOÁN (VNĐ): {currencyFormat(dulieu.giatien)}</p>
            <p className="font-weight-bold mb-0"><span className="text-uppercase">Số tiền viết bằng chữ:</span> <span className="first-uppercase">{docso(dulieu.giatien)}</span> đồng</p>
            <p className="font-weight-bold"><span className="text-uppercase">Thời gian trả kết quả:</span> 15 - 30 phút</p>
            <table className="table border-0">
                <tbody>
                    <tr>
                        <td className="border-0">
                            <p className="font-weight-bold text-center text-uppercase">Người nộp tiền</p>
                        </td>
                        <td className="border-0" width="50%">
                            <p className="font-weight-bold text-center text-uppercase">Người thu tiền</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="border-0 p-5 khoang-dongdau"></td>
                    </tr>
                    <tr>
                        <td className="border-0"></td>
                        <td className="border-0 align-bottom" width="50%">
                            <p className="font-weight-bold text-center text-uppercase mb-0">{dulieu.nhanvien ? dulieu.nhanvien.name : ''}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <footer>
                <p className="mb-0 text-uppercase font-weight-bold">đặt dịch vụ xét nghiệm covid-19 online</p>
                <p>Truy cập website: https://covid.xetnghiemtaman.com</p>
            </footer>
        </div>
    }
};