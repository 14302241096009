import React, { useEffect, useState, useRef,
  // useCallback
} from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody, Form, Button,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  FormInput
} from "shards-react";
import Pusher from 'pusher-js'
// import { debounce, filter } from 'lodash'

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import MyPagination from "../components/common/MyPagination";
// import { removeVietnameseTones } from '../utils/cachamchung'

const DanhsachKetqua = () => {
  const unmounted = useRef(false)
  const [list, setList] = useState([])
  // const [ketqua, setKetqua] = useState()
  const [timkiem, setTimkiem] = useState({
    txt: '',
    ngay: ''
  })
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [danhsach, setDanhsach] = useState()
  const [totPages, setTotPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
    channel.bind('ketqua', data => {
      getData(currentPage)
    });
    return () => { pusher.disconnect() }
  }, [])
  useEffect(() => {
    getData(1)
    return () => { unmounted.current = true }
  }, [])
  const getData = (page) => {
    axios.get(`danhsachketqua?page=${page}`).then(response => {
      if (!unmounted.current) {
        setTotPages(response.data.last_page)
        setDanhsach(response.data.data)
        // if (!searching) {
        //   setKetqua(response.data.data)
        // }
        // setList(response.data.data)
      }
    })
  }
  const handleChangePage = ele => {
    setCurrentPage(ele)
    getData(ele)
  }
  // const fetchDropdownOptions = (key) => {
  //   // console.log(key);
  //   // const kq = filter(list, (item) => item.dangky.hoten.toLowerCase().indexOf(key.trim())>-1 || removeVietnameseTones(item.dangky.hoten.toLowerCase()).indexOf(removeVietnameseTones(key.trim()))>-1 || item.sodienthoai.indexOf(key.trim())>-1 || item.dangky.cmnd.indexOf(key.trim())>-1)
  //   // setKetqua(kq)
  //   setList([])
  //   axios.get(`timkiemketqua?q=${key}`).then(response => {
  //     if (!unmounted.current) {
  //       if (response.data) {
  //         setList(response.data)
  //       }
  //     }
  //   })
  // }
  // const debounceDropDown = useCallback(debounce((nextValue) => fetchDropdownOptions(nextValue), 500), [])
  const handleTimkiem = e => {
    e.preventDefault()
    // console.log(timkiem);
    setSearching(true)
    if (!loading) {
      setLoading(true)
      axios.post(`timkiemketqua`, timkiem).then(response => {
        if (!unmounted.current) {
          setLoading(false)
          setList(response.data.data)
        }
      }).catch(err => setLoading(false))
    }
    // // setTimkiem(e.currentTarget.value)
    // if (e.currentTarget.value.trim()) {
    //   setSearching(true)
    // } else {
    //   setSearching(false)
    // }
    // debounceDropDown(e.currentTarget.value.trim().toLowerCase())
  }
  const handleInputTimkiem = e => {
    setTimkiem({...timkiem, [e.currentTarget.name]: e.currentTarget.value})
  }
  return <Container fluid className="main-content-container px-4">
    {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="12" title="Danh sách kết quả xét nghiệm" className="text-md-center" />
    </Row>

    <Row>
      <Col>
      <Card small className="mb-4">
          <CardBody className="pb-0">
            <Form onSubmit={handleTimkiem}>
            <Row form>
              <Col md="5" className="form-group">
                <FormInput
                  // className="navbar-search border-0 no-shadow"
                  placeholder="Search..."
                  name="txt"
                  value={timkiem.txt}
                  onChange={handleInputTimkiem}
                  // disabled={!list}
                />
              </Col>
              <Col md="3" className="form-group">
                <FormInput
                  // className="navbar-search border-0 no-shadow"
                  name="ngay"
                  value={timkiem.ngay}
                  onChange={handleInputTimkiem}
                  type="date"
                />
              </Col>
              <Col md="2" className="form-group">
                <Button type="submit" block>Search</Button>
              </Col>
              {searching && <Col md="2" className="form-group">
                <Button type="button" onClick={e=>{setSearching(false);setTimkiem({txt: '', ngay: ''})}} theme="danger" outline block>Cancel</Button>
              </Col>}
            </Row>
            </Form>
            {/* <InputGroup seamless>
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">search</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                className="navbar-search border-0 no-shadow"
                placeholder="Search..."
                // value={timkiem}
                onChange={handleTimkiem}
                // disabled={!list}
              />
              <FormInput
                className="navbar-search border-0 no-shadow col-3"
                type="date"
              />
            </InputGroup>
              <Button>Search</Button> */}
          </CardBody>
      </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            {/* {list.length} */}
            <div className="table-responsive">
              {searching?<table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th colSpan="10" scope="col" className="border-0">
                      Kết quả tìm kiếm: {list.length} được tìm thấy
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Mã phiếu
                    </th>
                    <th scope="col" className="border-0">
                      Họ tên
                    </th>
                    <th scope="col" className="border-0">
                      Giới tính
                    </th>
                    <th scope="col" className="border-0">
                      Ngày sinh
                    </th>
                    <th scope="col" className="border-0">
                      CMND
                    </th>
                    {/* <th scope="col" className="border-0">
                      Đ/c
                    </th> */}
                    <th scope="col" className="border-0">
                      Ngày kết quả
                    </th>
                    <th scope="col" className="border-0">
                      Kết quả
                    </th>
                    <th scope="col" className="border-0 text-center">
                      Lần in
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {list.length ? list.map((item, index) => <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle font-weight-bold">{item.maphieu}</td>
                    <td className="align-middle font-weight-bold text-nowrap text-capitalize"><NavLink to={`/chi-tiet/${item.dangky.id}`}><strong>{item.dangky.hoten}</strong></NavLink><p className="mb-0">SĐT: {item.dangky.sodienthoai}</p></td>
                    <td className="align-middle">{item.dangky.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                    <td className="align-middle">{dateFormat(item.dangky.ngaysinh, 'dd/mm/yyyy')}</td>
                    <td className="align-middle">{item.dangky.cmnd}</td>
                    {/* <td className="align-middle">{item.dangky.diachi}</td> */}
                    <td className="align-middle">{dateFormat(item.ngayphanloai, 'HH:MM, dd/mm/yyyy')}</td>
                    <td className={classNames("align-middle font-weight-bold", item.phanloai == 0 ? 'text-success' : 'text-danger')}>{item.phanloai == 0 ? 'Âm tính' : (item.phanloai == 1 ? 'Dương tính' : '')}</td>
                    <td className="align-middle text-center">{item.print_count}</td>
                    <td><NavLink to={`/chi-tiet/${item.dangky.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                  </tr>) : <tr><td className="text-center" colSpan="10">No records</td></tr>}
                </tbody>
                {/* {danhsach && totPages > 1?<tfoot>
                  <tr>
                    <td colSpan="10">
                      <MyPagination  totPages={totPages} currentPage={currentPage} pageClicked={handleChangePage} />
                    </td>
                  </tr>
                </tfoot>:null} */}
              </table>:<table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Mã phiếu
                    </th>
                    <th scope="col" className="border-0">
                      Họ tên
                    </th>
                    <th scope="col" className="border-0">
                      Giới tính
                    </th>
                    <th scope="col" className="border-0">
                      Ngày sinh
                    </th>
                    <th scope="col" className="border-0">
                      CMND
                    </th>
                    {/* <th scope="col" className="border-0">
                      Đ/c
                    </th> */}
                    <th scope="col" className="border-0">
                      Ngày kết quả
                    </th>
                    <th scope="col" className="border-0">
                      Kết quả
                    </th>
                    <th scope="col" className="border-0 text-center">
                      Lần in
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {danhsach ? (danhsach.length ? danhsach.map((item, index) => <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle font-weight-bold">{item.maphieu}</td>
                    <td className="align-middle font-weight-bold text-nowrap text-capitalize"><NavLink to={`/chi-tiet/${item.dangky.id}`}><strong>{item.dangky.hoten}</strong></NavLink><p className="mb-0">SĐT: {item.dangky.sodienthoai}</p></td>
                    <td className="align-middle">{item.dangky.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                    <td className="align-middle">{dateFormat(item.dangky.ngaysinh, 'dd/mm/yyyy')}</td>
                    <td className="align-middle">{item.dangky.cmnd}</td>
                    {/* <td className="align-middle">{item.dangky.diachi}</td> */}
                    <td className="align-middle">{dateFormat(item.ngayphanloai, 'HH:MM, dd/mm/yyyy')}</td>
                    <td className={classNames("align-middle font-weight-bold", item.phanloai == 0 ? 'text-success' : 'text-danger')}>{item.phanloai == 0 ? 'Âm tính' : (item.phanloai == 1 ? 'Dương tính' : '')}</td>
                    <td className="align-middle text-center">{item.print_count}</td>
                    <td><NavLink to={`/chi-tiet/${item.dangky.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                  </tr>) : <tr><td className="text-center" colSpan="10">No records</td></tr>) : <tr><td className="text-center" colSpan="10"><Spinner animation="border" role="status" /></td></tr>}
                </tbody>
                {danhsach && totPages > 1?<tfoot>
                  <tr>
                    <td colSpan="10">
                      <MyPagination  totPages={totPages} currentPage={currentPage} pageClicked={handleChangePage} />
                    </td>
                  </tr>
                </tfoot>:null}
              </table>}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
};

export default DanhsachKetqua;
