import React, { useState } from "react";
import { Container, Row, Col, Card, CardHeader, Form, ListGroup, ListGroupItem, FormInput, Button } from "shards-react";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { logout } from '../actions';
import { SESSION_SCOPE } from '../actions/sessionActions';
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails2";
import axios from "../utils/axios";
// import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

const UserProfileLite = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [dulieu, setDulieu] = useState({
    name: props.auth[SESSION_SCOPE].user.user.name,
    email: props.auth[SESSION_SCOPE].user.user.email,
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const handleSubmit = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`capnhattaikhoan`, {...dulieu, loai: 'thongtin'}).then(response => {
        setLoading(false)
        if (response.data) {
          //
        } else {
          window.alert(response.error)
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const handleSubmit2 = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`capnhattaikhoan`, {...dulieu, loai: 'doimatkhau'}).then(response => {
        setLoading(false)
        if (response.data) {
          Cookies.remove('user');
          dispatch(logout());
        } else {
          window.alert(response.error)
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const handleInputChange = e => {
    setDulieu({ ...dulieu, [e.currentTarget.name]: e.currentTarget.value })
  }
  return <Container fluid className="main-content-container px-4">
    {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
    <Row noGutters className="page-header py-4">
      <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <Row>
      <Col lg="4">
        <UserDetails />
      </Col>
      <Col lg="8">
        {/* <UserAccountDetails /> */}
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Account Details</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <Row form>
                      {/* First Name */}
                      <Col md="12" className="form-group">
                        <label htmlFor="feFirstName">Name</label>
                        <FormInput
                          required
                          id="feFirstName"
                          placeholder="Name"
                          name="name"
                          value={dulieu.name}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      {/* Email */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feEmail">Email</label>
                        <FormInput
                          required
                          type="email"
                          id="feEmail"
                          placeholder="Email Address"
                          name="email"
                          value={dulieu.email}
                          onChange={handleInputChange}
                          autoComplete="email"
                        />
                      </Col>
                    </Row>
                    <Button type="submit" theme="accent">Update Account</Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Đổi mật khẩu</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit2}>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label>Mật khẩu hiện tại</label>
                        <FormInput
                          required
                          type="password"
                          name="currentPassword"
                          value={dulieu.currentPassword}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label>Mật khẩu mới</label>
                        <FormInput
                          required
                          type="password"
                          name="newPassword"
                          value={dulieu.newPassword}
                          onChange={handleInputChange}
                        />
                      </Col>
                      {/* Password */}
                      <Col md="6" className="form-group">
                        <label>Xác thực lại mật khẩu</label>
                        <FormInput
                          required
                          type="password"
                          name="confirmPassword"
                          value={dulieu.confirmPassword}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" theme="accent">Đổi mật khẩu</Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  </Container>
};

const mapStateToProps = state => {
  return {
      auth: state
  };
};
export default connect(mapStateToProps)(UserProfileLite);
// export default UserProfileLite;
