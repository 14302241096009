import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SESSION_SCOPE } from '../actions/sessionActions';

const Overview = (props) => {
    useEffect(() => {
        const dulieu = props.auth[SESSION_SCOPE]
        if (dulieu.loggedIn) {
            if (dulieu.user.user.role === 'thungan') {
                props.history.replace('/danh-sach-hom-nay')
            } else if (dulieu.user.user.role === 'lab') {
                props.history.replace('/danh-sach-cho-ket-qua')
            } else if (dulieu.user.user.role === 'admin') {
                props.history.replace('/ket-qua')
            }
        } else {
            props.history.replace('/auth/login')
        }
    }, [props])
    return <React.Fragment />
};

const mapStateToProps = state => {
    return {
        auth: state
    };
};
export default connect(mapStateToProps)(Overview);