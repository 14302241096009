import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import Cookies from 'js-cookie';

import rootReducer from '../reducers';

import { SESSION_SCOPE } from '../actions/sessionActions';

// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);
  
  const user = Cookies.get('user');
  if (user) {
    preloadedState = {
      [SESSION_SCOPE]: JSON.parse(user)
    }
  }

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
