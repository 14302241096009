import React from "react";
import dateFormat from "dateformat";

export default class Phieuketqua extends React.Component {
    render() {
        const dulieu = this.props.dulieu
        return <div className="d-none d-print-block sheet padding-15mm">
            <table className="table border-0 header-page">
                <tbody>
                    <tr>
                        <td className="align-middle border-0 p-0"></td>
                        <td className="border-0 pl-0 pb-0 text-center pr-5rem">
                            <img src="https://xetnghiemtaman.com/logo2.png" style={{float: 'left', width: 90}} />
                            <p className="mb-0">SỞ Y TẾ NINH THUẬN</p>
                            <p className="mb-0 font-weight-bold">PHÒNG XÉT NGHIỆM<br /><u>Y KHOA TÂM AN</u></p>
                            {/* <p className="mb-0">577 Thống nhất, Phan Rang – Tháp Chàm</p>
                            <p className="mb-0">Tel: 0919.196.991 – 02593.518368</p> */}
                        </td>
                        <td className="border-0 pb-0" width="5%"></td>
                        <td className="border-0 pb-0 text-nowrap" width="50%">
                            <p className="mb-0 font-weight-bold text-center">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                            <p className="font-weight-bold text-center"><u>Độc lập – Tự do – Hạnh phúc</u></p>
                            {/* <p className="mb-0 text-center font-italic">Ninh Thuận, ngày {dateFormat(new Date(), 'dd')} tháng {dateFormat(new Date, 'mm')} năm {(new Date).getFullYear()}</p> */}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="border-0 pt-0 text-center">
                            {/* <p className="mb-0">SỞ Y TẾ NINH THUẬN</p>
                            <p className="mb-0 font-weight-bold">PHÒNG XÉT NGHIỆM<br /><u>Y KHOA TÂM AN</u></p> */}
                            <p className="mb-0">577 Thống nhất, Phan Rang – Tháp Chàm</p>
                            <p className="mb-0">Tel: 0919.196.991 – 02593.518368</p>
                        </td>
                        <td className="border-0 pt-0" width="5%"></td>
                        <td className="border-0 pt-0" width="50%">
                            {/* <p className="mb-0 font-weight-bold text-center">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                            <p className="font-weight-bold text-center"><u>Độc lập – Tự do – Hạnh phúc</u></p> */}
                            <p className="mb-0 text-right font-italic">Ninh Thuận, ngày {dateFormat(new Date(), 'dd')} tháng {dateFormat(new Date, 'mm')} năm {(new Date).getFullYear()}</p>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="align-middle border-0 p-0"><img src="https://xetnghiemtaman.com/logo2.png" /></td>
                        <td width="50%" className="border-0 pl-0 text-center">
                            <p className="mb-0">SỞ Y TẾ NINH THUẬN</p>
                            <p className="mb-0 font-weight-bold">PHÒNG XÉT NGHIỆM<br /><u>Y KHOA TÂM AN</u></p>
                            <p className="mb-0">577 Thống nhất, Phan Rang – Tháp Chàm</p>
                            <p className="mb-0">Tel: 0919.196.991 – 02593.518368</p>
                        </td>
                        <td className="border-0" width="50%">
                            <p className="mb-0 font-weight-bold text-center">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                            <p className="font-weight-bold text-center"><u>Độc lập – Tự do – Hạnh phúc</u></p>
                            <p className="mb-0 text-center font-italic">Ninh Thuận, ngày {dateFormat(new Date(), 'dd')} tháng {dateFormat(new Date, 'mm')} năm {(new Date).getFullYear()}</p>
                        </td>
                    </tr> */}
                </tbody>
            </table>
            <h5 className="text-center text-uppercase font-weight-bold mb-0">KẾT QUẢ XÉT NGHIỆM NHANH</h5>
            <h5 className="text-center font-weight-bold mb-0">PHÁT HIỆN KHÁNG NGUYÊN SARS-CoV-2</h5>
            <div className="gachduoi mb-3"></div>
            {/* <p className="text-center">Số: {dulieu.ketqua.maphieu}</p> */}
            <p className="mb-0">1. Loại bệnh phẩm: Dịch tỵ hầu;</p>
            <p className="mb-0">2. Yêu cầu xét nghiệm: Kháng nguyên virút SARS-CoV-2;</p>
            <p className="mb-0">3. Kỹ thuật xét nghiệm: Xét nghiệm nhanh phát hiện kháng nguyên SARS-CoV-2;</p>
            <p className="mb-0">4. Sinh phẩm sử dụng: Sugentech - Hàn Quốc;</p>
            <p className="mb-0">5. Địa điểm lấy mẫu: Phòng xét nghiệm y khoa Tâm An ;</p>
            <p className="mb-0">6. Kết quả xét nghiệm:</p>
            <table className="table table-bordered ketqua-table">
                <thead>
                    <tr className="font-weight-bold">
                        <td rowSpan="2" className="align-middle text-center">STT</td>
                        <td rowSpan="2" className="align-middle text-center">Họ và tên</td>
                        <td colSpan="2" className="align-middle text-center">Năm sinh</td>
                        <td rowSpan="2" className="align-middle text-center">Địa chỉ</td>
                        <td rowSpan="2" className="align-middle text-center">Ngày lấy mẫu</td>
                        <td rowSpan="2" className="align-middle text-center">Ngày xét nghiệm</td>
                        <td rowSpan="2" className="align-middle text-center">Kết quả xét nghiệm</td>
                    </tr>
                    <tr className="font-weight-bold">
                        <td className="align-middle text-center">Nam</td>
                        <td className="align-middle text-center">Nữ</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="align-middle text-center">1</td>
                        <td className="align-middle text-uppercase">{dulieu.hoten}</td>
                        <td className="align-middle text-center">{dulieu.gioitinh == 1 ? dateFormat(dulieu.ngaysinh, 'yyyy') : ''}</td>
                        <td className="align-middle text-center">{dulieu.gioitinh == 0 ? dateFormat(dulieu.ngaysinh, 'yyyy') : ''}</td>
                        <td className="align-middle text-center">{dulieu.diachi}</td>
                        <td className="text-nowrap align-middle text-center">{dateFormat(dulieu.ngaykhaibao, 'dd-mm-yyyy')}</td>
                        <td className="text-nowrap align-middle text-center">{dateFormat(dulieu.ketqua.ngayphanloai, 'dd-mm-yyyy')}</td>
                        <td className="align-middle font-weight-bold text-center">{dulieu.ketqua.phanloai == 1 ? 'Dương tính' : 'Âm tính'}</td>
                    </tr>
                </tbody>
            </table>
            <p className="text-center">(Phiếu này có giá trị trong vòng <strong className="font-weight-bold">03</strong> ngày, từ ngày <strong className="font-weight-bold">{dateFormat(dulieu.ketqua.ngayphanloai, 'dd-mm-yyyy')}</strong> đến hết ngày <strong className="font-weight-bold">{dateFormat(new Date(dateFormat(dulieu.ketqua.ngayphanloai, 'yyyy'), dateFormat(dulieu.ketqua.ngayphanloai, 'm') - 1, parseInt(dateFormat(dulieu.ketqua.ngayphanloai, 'd')) + 3), 'dd-mm-yyyy')}</strong>)</p>
            <table className="table border-0">
                <tbody>
                    <tr>
                        <td className="border-0 text-center align-bottom text-nowrap">
                            <p className="font-weight-bold">NGƯỜI XÉT NGHIỆM</p>
                        </td>
                        <td className="border-0" width="40%"></td>
                        <td className="border-0 align-bottom text-nowrap" width="50%">
                            <p className="font-italic text-center mb-2">Ngày, Giờ trả kết quả xét nghiệm:<br/>{dateFormat(dulieu.ketqua.ngayphanloai, 'HH')}h{dateFormat(dulieu.ketqua.ngayphanloai, 'MM')}, ngày {dateFormat(dulieu.ketqua.ngayphanloai, 'dd-mm-yyyy')}</p>
                            <p className="font-weight-bold text-center">PHÒNG XÉT NGHIỆM</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="border-0 p-5 khoang-dongdau"></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold border-0 text-center text-uppercase text-nowrap">{dulieu.ketqua.nhanvien.name}</td>
                        <td className="border-0"></td>
                        <td className="font-weight-bold text-center border-0 text-uppercase" width="50%">
                        phạm hoàng nguyệt
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
};