import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Toast } from 'react-bootstrap'

const Thongbao = props => {
    const [show, setShow] = useState(true)
    return <Toast onClose={() => {setShow(false);props.onClose(props.item.id)}} show={show} delay={props.item.thoigian || 5000} autohide>
    <Toast.Header>
      {/* <img
        src="http://holder.js/20x20"
        className="rounded mr-2"
        alt=""
      /> */}
      <strong className="mr-auto">{props.item.name}</strong>
      <small>{props.item.ngay}</small>
    </Toast.Header>
    <Toast.Body>{props.item.desc}</Toast.Body>
    {/* <Toast.Body>Woohoo, you're reading this text in a Toast! <NavLink to="/">Chi tiết</NavLink></Toast.Body> */}
  </Toast>
}
export default Thongbao;