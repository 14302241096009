import React, { useEffect, useState, useRef, useCallback } from "react";
import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody, Button, FormRadio, FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText } from "shards-react";
import Pusher from 'pusher-js'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { debounce, filter } from 'lodash'

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { removeVietnameseTones } from '../utils/cachamchung'

const DanhsachChoketqua = () => {
  const unmounted = useRef(false)
  const [list, setList] = useState()
  const [ketqua, setKetqua] = useState()
  const [timkiem, setTimkiem] = useState('')
  const [searching, setSearching] = useState(false)
  
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dulieu, setDulieu] = useState()
  const [thongtin, setThongtin] = useState({
    ketqua: -1,
    ngayketqua: dateFormat(new Date(), 'yyyy-mm-dd')
  })
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
    channel.bind('cholaymau', data => {
      getData()
    });
    return () => { pusher.disconnect() }
  }, [])
  useEffect(() => {
    getData()
    return () => { unmounted.current = true }
  }, [])
  const getData = () => {
    axios.get(`danhsachdanglaymau`).then(response => {
      if (!unmounted.current) {
        if (!searching) {
          setKetqua(response.data)
        }
        setList(response.data)
      }
    })
  }
  const handleNhapKetqua = item => {
    setDulieu(item)
    setShow(true)
  }
  const handleInputChange = e => {
    if (e.currentTarget.name) {
      setThongtin({...thongtin, [e.currentTarget.name]: e.currentTarget.value})
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`nhapketqua`, {
        dulieu: dulieu,
        thongtin: thongtin
      }).then(response => {
        setLoading(false)
        if (response.data) {
          setThongtin({...thongtin, ketqua: -1})
          setShow(false)
          getData()
        } else {
          window.alert(response.error)
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }

  const fetchDropdownOptions = (key, list) => {
    const kq = filter(list, (item) => item.hoten.toLowerCase().indexOf(key.trim())>-1 || removeVietnameseTones(item.hoten.toLowerCase()).indexOf(removeVietnameseTones(key.trim()))>-1 || item.mamau.indexOf(key.trim())>-1)
    setKetqua(kq)
  }
  const debounceDropDown = useCallback(debounce((nextValue, list) => fetchDropdownOptions(nextValue, list), 500), [])
  const handleTimkiem = e => {
    setTimkiem(e.currentTarget.value)
    debounceDropDown(e.currentTarget.value.toLowerCase(), list)
    if (e.currentTarget.value.trim()) {
      setSearching(true)
    } else {
      setSearching(false)
    }
  }
  return <React.Fragment>
    <Modal show={show} onHide={e => setShow(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Nhập thông tin kết quả</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loadingBox pt-5"><Spinner animation="border" role="status" /></div>}
        {dulieu ? <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md="12" className="form-group">
              <label><strong>Kết quả xét nghiệm</strong></label>
              <fieldset className="d-flex justify-content-around">
                <FormRadio name="ketqua" required value={0} onChange={handleInputChange} defaultChecked={thongtin.ketqua==0}>Âm tính</FormRadio>
                <span className="ml-5" />
                <FormRadio name="ketqua" required value={1} onChange={handleInputChange} defaultChecked={thongtin.ketqua==1}>Dương tính</FormRadio>
              </fieldset>
            </Col>
            <Col md="6" className="form-group">
              <label>Ngày xét nghiệm</label>
              <FormInput
                required
                type="date"
                placeholder="Ngày xét nghiệm"
                name="ngayketqua"
                value={thongtin.ngayketqua}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Button block type="submit" className="form-group">Lưu</Button>
          <Row>
            <Col>
              <h4>Mã mẫu: {dulieu.mamau}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="mb-0">Họ tên: {dulieu.hoten}</h4>
            </Col>
          </Row>
        </Form> : null}
      </Modal.Body>
    </Modal>
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="12" title="Danh sách chờ nhập kết quả" className="text-md-center" />
      </Row>

      <Row>
        <Col>
        <Card small className="mb-4">
            <CardBody>
              <InputGroup seamless>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <i className="material-icons">search</i>
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  className="navbar-search border-0 no-shadow"
                  placeholder="Search..."
                  value={timkiem}
                  onChange={handleTimkiem}
                  disabled={!list}
                />
              </InputGroup>
            </CardBody>
        </Card>
        </Col>
      </Row>
      
      <Row>
        {list ? (ketqua.length ? ketqua.map((item, index) =>
          <Col className="col-lg mb-4" md="6" sm="6" key={index}>
            <Card small className="stats-small stats-small--1">
              <CardBody className="p-0 d-flex">
                <div className="d-flex flex-column m-auto">
                  <div className="stats-small__data text-center">
                    <span className="stats-small__label text-uppercase">{item.hoten}</span>
                    <h6 className="stats-small__value count my-3">{item.mamau}</h6>
                  </div>
                  <div className="stats-small__data">
                    <Button outline onClick={e => handleNhapKetqua(item)}>Chi tiết</Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ) : null) : <Col className="text-center"><Spinner animation="border" role="status" /></Col>}
      </Row>
    </Container>
  </React.Fragment>
};

export default DanhsachChoketqua;
