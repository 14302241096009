import React from 'react'
import { Route, Redirect } from 'react-router'
import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { SESSION_SCOPE } from '../actions/sessionActions';

const RequireAuthentication = ({ component: Component, authPath, routes, ...rest }) => {
    const location = useLocation();
    const matchedRoutes = matchRoutes(routes, location.pathname);
    let matchCurrent = null
    if (matchedRoutes.length === 1) {
        matchCurrent = matchedRoutes[0]
    } else {
        matchCurrent = matchedRoutes[1]
    }
    if (!matchCurrent.route.requireLogin || rest.auth[SESSION_SCOPE].loggedIn) {
        return (
            <Route
                {...rest}
                render={props => <Component {...props} />}
            />
        )
    }
    const redirPath = authPath ? authPath : '/auth/login'
    return <Redirect to={{ pathname: redirPath }} />
}

const mapStateToProps = state => {
    return {
        auth: state
    };
};
export default connect(mapStateToProps)(RequireAuthentication);