import React, {
  useEffect, useState, useRef
} from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import {
  Container, Row, Col, Card, CardBody,
  FormInput,
  Form,
  Button
} from "shards-react";

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { Spinner } from "react-bootstrap";

const DanhsachKhaibao = () => {
  const unmounted = useRef(false)
  const [loading, setLoading] = useState(false)
  const [danhsach, setDanhsach] = useState()
  useEffect(() => {
    return () => { unmounted.current = true }
  }, [])
  const [ketqua, setKetqua] = useState()
  const [timkiem, setTimkiem] = useState({
    ngay: dateFormat(new Date(), 'yyyy-mm-dd')
  })
  const handleInputTimkiem = e => {
    setTimkiem({ ...timkiem, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleTimkiem = e => {
    e.preventDefault()
    setLoading(true)
    axios.post(`timkiemkhaibao`, timkiem).then(response => {
      if (!unmounted.current) {
        setLoading(false)
        setKetqua(timkiem)
        setDanhsach(response.data)
      }
    }).catch(err => setLoading(false))
  }
  const handleDownload = e => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `thong-ke-khai-bao-${ketqua.ngay}.xlsx`
    axios.post(`thongkekhaibao`, ketqua, { responseType: "blob" }).then(response => {
      link.href = URL.createObjectURL(
        new Blob([response])
      );
      link.click();
    }).catch(err => { })
  }
  return <Container fluid className="main-content-container px-4">
    {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="12" title="Danh sách khai báo y tế" className="text-md-center" />
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody className="pb-0">
            <Form onSubmit={handleTimkiem}>
              <Row form>
                <Col md="3" className="form-group offset-md-4">
                  <FormInput
                    // className="navbar-search border-0 no-shadow"
                    required
                    name="ngay"
                    value={timkiem.ngay}
                    onChange={handleInputTimkiem}
                    type="date"
                  />
                </Col>
                <Col md="1" className="form-group">
                  <Button type="submit" block>Search</Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>

    {danhsach ? <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th colSpan="7" scope="col" className="border-0 align-middle">
                      Kết quả tìm kiếm: {danhsach.length} được tìm thấy
                    </th>
                    <th scope="col" className="border-0">
                      <Button size="sm" onClick={handleDownload} type="button" block>Download</Button>
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Ngày khai báo
                    </th>
                    <th scope="col" className="border-0">
                      Họ tên
                    </th>
                    <th scope="col" className="border-0">
                      Giới tính
                    </th>
                    <th scope="col" className="border-0">
                      Ngày sinh
                    </th>
                    <th scope="col" className="border-0">
                      CMND
                    </th>
                    <th scope="col" className="border-0">
                      Loại
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {danhsach.length ? danhsach.map((item, index) => <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle font-weight-bold">{dateFormat(item.ngaykhaibao, 'dd/mm/yyyy')}</td>
                    <td className="align-middle font-weight-bold text-nowrap text-capitalize"><NavLink to={`/chi-tiet-khai-bao/${item.id}`}><strong>{item.hoten}</strong></NavLink><p className="mb-0">SĐT: {item.sodienthoai}</p></td>
                    <td className="align-middle">{item.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                    <td className="align-middle">{dateFormat(item.ngaysinh, 'dd/mm/yyyy')}</td>
                    <td className="align-middle">{item.cmnd}</td>
                    <td className="align-middle">{item.loai == 1 ? 'XN Covid' : (item.loai == 2 ? '' : 'XN khác')}</td>
                    <td><NavLink to={`/chi-tiet-khai-bao/${item.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                  </tr>) : <tr><td className="text-center" colSpan="10">No records</td></tr>}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row> : null}
  </Container>
};

export default DanhsachKhaibao;
