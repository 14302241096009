import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Card,
    ListGroup,
    ListGroupItem,
    Form,
    FormInput,
    FormGroup,
    Button,
    Alert
} from "shards-react";
import { useDispatch } from 'react-redux';
import { login } from '../actions';
import Cookies from 'js-cookie';

const Login = ({ history }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState(null)
    const [thongtin, setThongtin] = useState({
        email: '',
        password: ''
    })
    useEffect(() => {
        const dulieu = JSON.parse(localStorage.getItem('ghinho'))
        if (dulieu) {
            setRemember(dulieu.remember)
            setThongtin(dulieu.data)
        }
    }, [])
    const handleSubmit = e => {
        e.preventDefault()
        if (!loading) {
            if (remember) {
                localStorage.setItem('ghinho', JSON.stringify({
                    remember: remember,
                    data: thongtin
                }))
            } else {
                localStorage.setItem('ghinho', null)
            }
            setLoading(true)
            fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(thongtin),
            }).then(response => response.json()).then(result => {
                setLoading(false)
                if (result.data) {
                    Cookies.set('user', JSON.stringify({ loggedIn: true, user: result.data }))
                    dispatch(login(result.data))
                    history.replace('/')
                } else if (result.error) {
                    setErrMsg(result.error)
                }
            }).catch(err => {
                setLoading(false)
            })
        }
    }
    const handleOnChange = e => {
        setThongtin({ ...thongtin, [e.currentTarget.name]: e.currentTarget.value })
    }
    const [remember, setRemember] = useState(false)
    const handleRemember = e => {
        setRemember(e.currentTarget.checked)
    }
    return <Container fluid className="main-content-container">
        <Row className="align-items-center vh-100">
            <Col className="col-md-4 offset-md-4">
                <Card small>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col>
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <label htmlFor="feEmailAddress">Username</label>
                                            <FormInput
                                                name="email"
                                                value={thongtin.email}
                                                onChange={handleOnChange}
                                                id="feEmailAddress"
                                                placeholder="Username"
                                                required
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="fePassword">Password</label>
                                            <FormInput
                                                type="password"
                                                name="password"
                                                value={thongtin.password}
                                                onChange={handleOnChange}
                                                id="fePassword"
                                                placeholder="Password"
                                                required
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <div className="checkbox">
                                                <label className="d-flex align-items-center mb-0"><input type="checkbox" className="mr-1" checked={remember} onChange={handleRemember} /> Remember?</label>
                                            </div>
                                        </FormGroup>
                                        {errMsg && <FormGroup>
                                            <Alert theme="danger" className="mb-0">
                                                <i className="fa fa-info mx-2"></i> Error: {errMsg}
                                            </Alert>
                                        </FormGroup>}
                                        <Button type="submit" disabled={loading} block>Login</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    </Container>
};

export default Login;
