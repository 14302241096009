import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import Cookies from 'js-cookie';
import { logout } from '../../../../actions';
import { SESSION_SCOPE } from '../../../../actions/sessionActions';

const UserActions = (props) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)

  const toggleUserActions = () => {
    setVisible(!visible)
  }

  const handleLogout = () => {
    Cookies.remove('user');
    dispatch(logout());
  }

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
          className="user-avatar rounded-circle mr-2"
          src="https://xetnghiemtaman.com/logo2.png"
          alt="User Avatar"
        />{" "}
        <span className="d-none d-md-inline-block">{props.auth[SESSION_SCOPE].user.user.name}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} small open={visible}>
        <DropdownItem tag={Link} to="/user-profile">
          <i className="material-icons">&#xE7FD;</i> Profile
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="edit-user-profile">
          <i className="material-icons">&#xE8B8;</i> Edit Profile
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/" className="text-danger" onClick={handleLogout}>
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
}
const mapStateToProps = state => {
  return {
    auth: state
  };
};
export default connect(mapStateToProps)(UserActions);