import React, { useState, useEffect, useRef } from "react"
import { Modal, Spinner } from 'react-bootstrap'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormInput, FormSelect, Button, FormRadio } from "shards-react";
import dateFormat from "dateformat";
import { connect } from "react-redux";
import { SESSION_SCOPE } from '../actions/sessionActions';

import PageTitle from "../components/common/PageTitle";
import axios from "../utils/axios";
import { currencyFormat, docso } from "../utils/cachamchung";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const ChitietCongty = (props) => {
  const unmounted = useRef(false)
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [danhsach, setDanhsach] = useState([])
  const [dulieu, setDulieu] = useState()
  const [spList, setSpList] = useState([])
  useEffect(() => {
    axios.get(`sinhpham`).then(response => {
      if (!unmounted.current) {
        if (response.data) {
          setSpList(response.data)
        }
      }
    })
    getData()
    return () => { unmounted.current = true }
  }, [])
  const getData = () => {
    setLoading(true)
    axios.get(`congty/chitiet/${props.match.params.id}`).then(response => {
      if (!unmounted.current) {
        setLoading(false)
        if (response.data) {
          setDulieu(response.data)
          response.data.ngaythuchien = dateFormat(response.data.ngaythuchien, 'yyyy-mm-dd')
          setThongtin2(response.data)
          const dsNhansu = response.data.nhansu.map(i => ({ ...i, selected: false }))
          setDanhsach(dsNhansu)
        } else {
          props.history.replace('/xet-nghiem-cong-ty')
        }
      }
    }).catch(err => { setLoading(false); props.history.replace('/xet-nghiem-cong-ty') })
  }
  const [selAll, setSelAll] = useState(false)
  const onSelectAll = e => {
    setSelAll(e.currentTarget.checked)
    setDanhsach(danhsach.map(i => ({ ...i, selected: e.currentTarget.checked })))
  }
  const onSelectItem = (e, item) => {
    const dsNhansu = danhsach.map(i => {
      if (i.id == item.id) {
        return { ...i, selected: e.currentTarget.checked }
      } else {
        return i
      }
    })
    setDanhsach(dsNhansu)
  }
  useEffect(() => {
    if (danhsach.length) {
      let tongSelect = 0
      for (let i = 0; i < danhsach.length; i++) {
        if (danhsach[i].selected) {
          tongSelect += 1
        }
      }
      if (tongSelect == danhsach.length) {
        setSelAll(true)
      } else {
        setSelAll(false)
      }
      if (tongSelect) {
        setShow2(true)
      } else {
        setShow2(false)
      }
    }
  }, [danhsach])
  const handleLuachonSinhpham = e => {
    const tim = spList.filter(item => item.id == e.currentTarget.value)
    if (tim.length) {
      setThongtin2({ ...thongtin2, tenthuoc: tim[0].tenthuoc, giatien: tim[0].giatien })
    }
  }
  const handleSubmit2 = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`themnhansu`, {
        dulieu: dulieu,
        thongtin: thongtin
      }).then(response => {
        setLoading(false)
        if (response.data) {
          setDanhsach([...danhsach, response.data])
          setShow(false)
          setThongtin({
            ...thongtin,
            hoten: '',
            gioitinh: '1',
            namsinh: '',
            diachi: ''
          })
          setDulieu({ ...dulieu, tinhtrang: response.tinhtrang })
        } else if (response.error) {
          window.alert(response.error)
        } else if (response.reload) {
          setShow(false)
          getData()
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const [thongtin, setThongtin] = useState({
    hoten: '',
    gioitinh: '1',
    namsinh: '',
    diachi: ''
  })
  const [nhansu, setNhansu] = useState()
  const [showNS, setShowNS] = useState(false)
  const handleInputChange2 = (e) => {
    setThongtin({ ...thongtin, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleChitiet = item => {
    item.ngaythuchien = dateFormat(item.ngaythuchien, 'yyyy-mm-dd')
    item.ketqua2 = item.ketqua
    setNhansu(item)
    setShowNS(true)
  }
  const handleDelete = item => {
    if (!loading) {
      if (danhsach.length > 1) {
        if (window.confirm('Có chắc chắn muốn xoá không?')) {
          setLoading(true)
          axios.post(`xoanhansu`, item).then(response => {
            setLoading(false)
            if (response.data) {
              setDanhsach(danhsach.filter(itm => itm.id != item.id))
              setDulieu({ ...dulieu, tinhtrang: response.tinhtrang })
            } else if (response.error) {
              window.alert(response.error)
            } else if (response.reload) {
              getData()
            }
          }).catch(err => {
            setLoading(false)
            window.alert(err)
          })
        }
      }
    }
  }
  const handleSubmit4 = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`capnhatnhansu`, nhansu).then(response => {
        setLoading(false)
        if (response.data) {
          setDanhsach(danhsach.map(itm => {
            if (itm.id == nhansu.id) {
              return response.data
            } else {
              return itm
            }
          }))
          setShowNS(false)
          setDulieu({ ...dulieu, tinhtrang: response.tinhtrang })
        } else if (response.error) {
          window.alert(response.error)
        } else if (response.reload) {
          setShowNS(false)
          getData()
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const handleInputChange4 = (e) => {
    if (e.currentTarget.name) {
      setNhansu({ ...nhansu, [e.currentTarget.name]: e.currentTarget.value })
    }
  }
  const [thongtin2, setThongtin2] = useState()
  const handleInputChange3 = (e) => {
    setThongtin2({ ...thongtin2, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleDownloadXacnhan = e => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "giay-xac-nhan.xlsx"
    axios.post(`giayxacnhan`, dulieu, { responseType: "blob" }).then(response => {
      link.href = URL.createObjectURL(
        new Blob([response])
      );
      link.click();
    }).catch(err => { })
  }
  const handleDownloadChitietKetqua = e => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "giay-ket-qua-chi-tiet.xlsx"
    axios.post(`giayketquachitiet`, dulieu, { responseType: "blob" }).then(response => {
      link.href = URL.createObjectURL(
        new Blob([response])
      );
      link.click();
    }).catch(err => { })
  }
  const handleDownloadKetqua = e => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "giay-ket-qua.xlsx"
    axios.post(`giayketqua`, dulieu, { responseType: "blob" }).then(response => {
      link.href = URL.createObjectURL(
        new Blob([response])
      );
      link.click();
    }).catch(err => { })
  }
  const [thongtin3, setThongtin3] = useState({
    ngaythuchien: dateFormat(new Date(), 'yyyy-mm-dd'),
    ketqua: -1
  })
  const handleInputChange5 = e => {
    if (e.currentTarget.name) {
      setThongtin3({ ...thongtin3, [e.currentTarget.name]: e.currentTarget.value })
    }
  }
  const handleSubmit5 = e => {
    e.preventDefault()
    if (!loading) {
      const dsNhansu = danhsach.filter(item => item.selected == true)
      setLoading(true)
      axios.post(`capnhatdsnhansu`, {
        dulieu: dulieu,
        thongtin: thongtin3,
        nhansu: dsNhansu
      }).then(response => {
        setLoading(false)
        if (response.data) {
          const dsNhansuNew = response.data.map(i => ({ ...i, selected: false }))
          setDanhsach(dsNhansuNew)
          setShow3(false)
          setDulieu({ ...dulieu, tinhtrang: response.tinhtrang })
        } else if (response.error) {
          window.alert(response.error)
        } else if (response.reload) {
          setShow3(false)
          getData()
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const handleXoa = e => {
    if (!loading) {
      if (window.confirm('Có chắc chắn muốn xoá không?')) {
        setLoading(true)
        axios.post(`xoacongty`, dulieu).then(response => {
          setLoading(false)
          if (response.data) {
            props.history.replace(`/xet-nghiem-cong-ty`)
          } else if (response.error) {
            window.alert(response.error)
          } else if (response.reload) {
            getData()
          }
        }).catch(err => {
          setLoading(false)
          window.alert(err)
        })
      }
    }
  }
  const [chinhsua, setChinhsua] = useState(false)
  const handleSubmit3 = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`capnhatcongty`, thongtin2).then(response => {
        setLoading(false)
        if (response.data) {
          setDulieu(response.data)
          response.data.ngaythuchien = dateFormat(response.data.ngaythuchien, 'yyyy-mm-dd')
          setThongtin2(response.data)
          setChinhsua(false)
        } else if (response.error) {
          window.alert(response.error)
        } else if (response.reload) {
          setChinhsua(false)
          getData()
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const handleKetso = e => {
    if (!loading) {
      if (window.confirm('Bạn có chắc chắn không?')) {
        setLoading(true)
        axios.post(`ketsocongty`, dulieu).then(response => {
          setLoading(false)
          if (response.data) {
            setDulieu({ ...dulieu, tinhtrang: response.data })
          } else if (response.error) {
            window.alert(response.error)
          } else if (response.reload) {
            getData()
          }
        }).catch(err => {
          setLoading(false)
          window.alert(err)
        })
      }
    }
  }
  return <React.Fragment>
    <Modal show={show3} onHide={e => setShow3(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Nhập kết quả</Modal.Title>
      </Modal.Header>
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      {dulieu ? <Form onSubmit={handleSubmit5}>
        <Modal.Body className="p-4">
          <Row form>
            <Col md="6" className="form-group">
              <label>Ngày thực hiện</label>
              <FormInput
                required
                type="date"
                name="ngaythuchien"
                value={thongtin3.ngaythuchien}
                onChange={handleInputChange5}
              />
            </Col>
            <Col md="12">
              <label><strong>Kết quả xét nghiệm</strong></label>
              <fieldset className="d-flex justify-content-around">
                <FormRadio name="ketqua" required value={0}
                  onChange={handleInputChange5} defaultChecked={thongtin3.ketqua == 0}
                >Âm tính</FormRadio>
                <span className="ml-5" />
                <FormRadio name="ketqua" required value={1}
                  onChange={handleInputChange5} defaultChecked={thongtin3.ketqua == 1}
                >Dương tính</FormRadio>
              </fieldset>
            </Col>
          </Row>
        </Modal.Body>
        {dulieu.tinhtrang <= 1 && <Modal.Footer>
          <Button type="submit">Lưu</Button>
        </Modal.Footer>}
      </Form> : null}
    </Modal>
    <Modal show={showNS} onHide={e => setShowNS(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Thông tin nhân sự</Modal.Title>
      </Modal.Header>
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      {nhansu ? <Form onSubmit={handleSubmit4}>
        <Modal.Body className="p-4">
          <Row form>
            <Col md="8" className="form-group">
              <label>Họ tên</label>
              <div className="form-control">{nhansu.hoten}</div>
            </Col>
            <Col md="2" className="form-group">
              <label>Giới tính</label>
              <div className="form-control">{nhansu.gioitinh == 1 ? 'Nam' : 'Nữ'}</div>
            </Col>
            <Col md="2" className="form-group">
              <label>Năm sinh</label>
              <div className="form-control">{nhansu.namsinh}</div>
            </Col>
            <Col md="12" className="form-group">
              <label>Địa chỉ</label>
              <div className="form-control">{nhansu.diachi}</div>
            </Col>
            <Col md="6" className="form-group">
              <label>Ngày thực hiện</label>
              <FormInput
                required
                type="date"
                name="ngaythuchien"
                value={nhansu.ngaythuchien}
                onChange={handleInputChange4}
              />
            </Col>
            <Col md="12">
              <label><strong>Kết quả xét nghiệm</strong></label>
              <fieldset className="d-flex justify-content-around">
                <FormRadio name="ketqua" required value={0}
                  onChange={handleInputChange4} defaultChecked={nhansu.ketqua == 0}
                >Âm tính</FormRadio>
                <span className="ml-5" />
                <FormRadio name="ketqua" required value={1}
                  onChange={handleInputChange4} defaultChecked={nhansu.ketqua == 1}
                >Dương tính</FormRadio>
              </fieldset>
            </Col>
          </Row>
        </Modal.Body>
        {dulieu.tinhtrang <= 1 && <Modal.Footer>
          <Button type="submit">Lưu</Button>
        </Modal.Footer>}
      </Form> : null}
    </Modal>
    <Modal show={show} onHide={e => setShow(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Nhập thông tin nhân sự</Modal.Title>
      </Modal.Header>
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      <Form onSubmit={handleSubmit2}>
        <Modal.Body className="p-4">
          <Row form>
            <Col md="12" className="form-group">
              <label>Họ tên</label>
              <FormInput
                required
                name="hoten"
                value={thongtin.hoten}
                onChange={handleInputChange2}
              />
            </Col>
            <Col md="6" className="form-group">
              <label>Giới tính</label>
              <FormSelect name="gioitinh" value={thongtin.gioitinh} onChange={handleInputChange2}>
                <option value="1">Nam</option>
                <option value="2">Nữ</option>
              </FormSelect>
            </Col>
            <Col md="6" className="form-group">
              <label>Năm sinh</label>
              <FormInput
                required
                maxLength={4}
                name="namsinh"
                value={thongtin.namsinh}
                onChange={handleInputChange2}
              />
            </Col>
            <Col md="12">
              <label>Địa chỉ</label>
              <FormInput
                required
                name="diachi"
                value={thongtin.diachi}
                onChange={handleInputChange2}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer><Button type="submit">Lưu</Button></Modal.Footer>
      </Form>
    </Modal>
    <Container fluid className="main-content-container px-4 pb-4">
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="12" title="Công ty xét nghiệm Covid-19" className="text-md-center" />
      </Row>
      {chinhsua ? <Row>
        <Col lg="12" className="mb-4 text-right">
          <Button type="button" onClick={e => setChinhsua(false)}>Cancel</Button>
        </Col>
      </Row> : <Row>
        <Col lg="12" className="mb-4 text-right">
          <Button type="button" onClick={e => props.history.goBack()}>Trở về</Button>
          {props.auth[SESSION_SCOPE].user.user.role == 'admin' ? <React.Fragment><Button type="button" onClick={e => setChinhsua(true)} className="ml-3">Chỉnh sửa</Button>
            {dulieu && dulieu.tinhtrang <= 1 && <Button type="button" className="ml-3" onClick={handleXoa} theme="danger">Xoá</Button>}</React.Fragment> : null}
        </Col>
      </Row>}
      {dulieu ? (chinhsua ? <React.Fragment>
        <Form onSubmit={handleSubmit3}>
          <Row>
            <Col lg="6">
              <Card small className="mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Thông tin công ty</h6>
                </CardHeader>
                <CardBody>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label>Tên công ty</label>
                      <FormInput
                        required
                        name="tencongty"
                        value={thongtin2.tencongty}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="12" className="form-group">
                      <label>Địa chỉ</label>
                      <FormInput
                        name="diachi"
                        value={thongtin2.diachi}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Người liên hệ</label>
                      <FormInput
                        name="lienhe"
                        value={thongtin2.lienhe}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Điện thoại</label>
                      <FormInput
                        maxLength="10"
                        type="tel"
                        name="dienthoai"
                        value={thongtin2.dienthoai}
                        onChange={handleInputChange3}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card small className="mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Thông tin xét nghiệm</h6>
                </CardHeader>
                <CardBody>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label>Người thực hiện test</label>
                      <FormInput
                        name="thuchien"
                        value={thongtin2.thuchien}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Người báo cáo</label>
                      <FormInput
                        name="baocao"
                        value={thongtin2.baocao}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label>Ngày thực hiện</label>
                      <FormInput
                        type="date"
                        name="ngaythuchien"
                        value={thongtin2.ngaythuchien}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="8" className="form-group">
                      <label>Chọn sinh phẩm</label>
                      <FormSelect
                        value={""} onChange={handleLuachonSinhpham}
                      >
                        <option value="">Chọn sinh phẩm</option>
                        {spList.map((item, index) => <option value={item.id} key={index}>{item.tenthuoc} ({item.mota}) / Giá: {currencyFormat(item.giatien)} đ</option>)}
                      </FormSelect>
                    </Col>
                    <Col md="8" className="form-group">
                      <label>Tên sinh phẩm</label>
                      <FormInput
                        required
                        name="tenthuoc"
                        value={thongtin2.tenthuoc}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="4">
                      <label>Đơn giá</label>
                      <FormInput
                        required
                        type="number"
                        name="giatien"
                        value={thongtin2.giatien}
                        onChange={handleInputChange3}
                      />
                    </Col>
                    <Col md="12" className="first-uppercase text-right font-italic">{docso(thongtin2.giatien)}</Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Button type="submit" theme="primary" block size="lg">Cập nhật</Button>
        </Form>
      </React.Fragment> : <React.Fragment>
        <Row>
          <Col lg="6">
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin công ty</h6>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="12" className="form-group">
                    <label>Tên công ty</label>
                    <div className="form-control">{dulieu.tencongty}</div>
                  </Col>
                  <Col md="12" className="form-group">
                    <label>Địa chỉ</label>
                    <div className="form-control">{dulieu.diachi || '-'}</div>
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Người liên hệ</label>
                    <div className="form-control">{dulieu.lienhe || '-'}</div>
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Điện thoại</label>
                    <div className="form-control">{dulieu.dienthoai || '-'}</div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin xét nghiệm</h6>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="6" className="form-group">
                    <label>Người thực hiện test</label>
                    <div className="form-control">{dulieu.thuchien}</div>
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Người báo cáo</label>
                    <div className="form-control">{dulieu.baocao}</div>
                  </Col>
                  <Col md="4" className="form-group">
                    <label>Ngày thực hiện</label>
                    <div className="form-control">{dateFormat(dulieu.ngaythuchien, 'dd-mm-yyyy')}</div>
                  </Col>
                  <Col md="8" className="form-group"></Col>
                  {props.auth[SESSION_SCOPE].user.user.role == 'admin' ? <React.Fragment><Col md="8" className="form-group">
                    <label>Tên sinh phẩm</label>
                    <div className="form-control">{dulieu.tenthuoc}</div>
                  </Col>
                    <Col md="4">
                      <label>Đơn giá</label>
                      <div className="form-control">{currencyFormat(dulieu.giatien)}</div>
                    </Col>
                    <Col md="12" className="first-uppercase text-right font-italic">{docso(dulieu.giatien)}</Col></React.Fragment> : null}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Nhân sự công ty đăng ký test</h6>
              </CardHeader>
              <CardBody className="danhsach-congty">
                <Row>
                  <Col className="mb-3 d-flex justify-content-end">
                    {show2 && <Button type="button" size="sm" onClick={e => setShow3(true)} className="mr-auto">Nhập kết quả</Button>}
                    <Button type="button" size="sm" className="ml-3" onClick={handleDownloadXacnhan}>In xác nhận</Button>
                    {dulieu.tinhtrang > 0 && <React.Fragment><Button type="button" onClick={handleDownloadKetqua} size="sm" className="ml-3">In kết quả</Button><Button type="button" onClick={handleDownloadChitietKetqua} size="sm" className="ml-3">In chi tiết</Button></React.Fragment>}
                    {dulieu.tinhtrang <= 1 && <Button type="button" size="sm" onClick={e => setShow(true)} className="ml-3">Thêm nhân sự</Button>}
                  </Col>
                </Row>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-bottom-0 text-center">
                          <input type="checkbox" checked={selAll} onChange={onSelectAll} />
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          STT
                        </th>
                        <th scope="col" className="border-bottom-0">
                          Họ tên
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          Giới tính
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          Năm sinh
                        </th>
                        <th scope="col" className="border-bottom-0">
                          Địa chỉ
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          Ngày thực hiện
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          Kết quả
                        </th>
                        <th scope="col" className="border-bottom-0"></th>
                        {props.auth[SESSION_SCOPE].user.user.role == 'admin' && dulieu.tinhtrang <= 1 && <th scope="col" className="border-bottom-0"></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {danhsach.map((item, index) => <tr key={index}>
                        <td className="align-middle text-center">
                          <input type="checkbox" checked={item.selected} onChange={e => onSelectItem(e, item)} />
                        </td>
                        <td className="align-middle text-center">{index + 1}</td>
                        <td className="align-middle font-weight-bold text-nowrap text-capitalize text-primary" onClick={e => handleChitiet(item)}><strong>{item.hoten}</strong></td>
                        <td className="align-middle text-center">{item.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                        <td className="align-middle text-center">{item.namsinh}</td>
                        <td className="align-middle">{item.diachi}</td>
                        <td className="align-middle text-center">{item.ketqua != -1 ? dateFormat(item.ngaythuchien, 'dd-mm-yyyy') : ''}</td>
                        <td className="align-middle text-center">{item.ketqua == -1 ? '' : (item.ketqua == 1 ? <span className="text-danger font-weight-bold">Dương tính</span> : <span className="text-success">Âm tính</span>)}</td>
                        <td className="align-middle"><Button type="button" onClick={e => handleChitiet(item)} size="sm" block outline theme="danger">Chi tiết</Button></td>
                        {props.auth[SESSION_SCOPE].user.user.role == 'admin' && dulieu.tinhtrang <= 1 && <td className="align-middle"><Button type="button" onClick={e => handleDelete(item)} size="sm" block theme="danger">Xoá</Button></td>}
                      </tr>)}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
            {dulieu.tinhtrang == 1 && (props.auth[SESSION_SCOPE].user.user.role == 'admin') && <Button type="button" onClick={handleKetso} theme="primary" block size="lg">Kết sổ</Button>}
          </Col>
        </Row>
      </React.Fragment>) : null}
    </Container>
  </React.Fragment>
};

const mapStateToProps = state => {
  return {
    auth: state
  };
};
export default connect(mapStateToProps)(ChitietCongty);
// export default ChitietCongty;
