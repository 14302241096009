import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'content-type': 'application/json',
    },
    // paramsSerializer: params => queryString.stringify(params),
});

instance.interceptors.request.use(async (config) => {
    let user = Cookies.get('user');
    if (user) {
        user = JSON.parse(user).user;
        config.headers.Authorization = `${user.token_type} ${user.access_token}`;
        config.headers.merchantId = user.store;
    }

    return config;
});

instance.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        Cookies.remove('user');
        window.location.href = '/'
        return
    }
    // Handle errors
    throw error;
});

// axiosClient.interceptors.response.use((response) => {
//     if (response && response.data) {
//         return response.data;
//     }

//     return response;
// }, (error) => {
//     // console.log(error.response);
//     if (error.response && error.response.status === 401) {
//         window.location.href = './'
//         return
//     }
//     // Handle errors
//     throw error;
// });

export default instance;
