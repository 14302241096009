import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import { SESSION_SCOPE } from '../actions/sessionActions';

const rootReducer = combineReducers({
  [SESSION_SCOPE]: sessionReducer
});

export default rootReducer;
