import React, { useEffect, useState, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody, Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput } from "shards-react";
import Pusher from 'pusher-js'
import { debounce, filter } from 'lodash'

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { Spinner } from "react-bootstrap";
import { removeVietnameseTones } from '../utils/cachamchung'

const Danhsach = () => {
  const unmounted = useRef(false)
  const [list, setList] = useState()
  const [ketqua, setKetqua] = useState()
  const [timkiem, setTimkiem] = useState('')
  const [searching, setSearching] = useState(false)
  
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
    channel.bind('khaibao', data => {
      getData()
    });
    return () => { pusher.disconnect() }
  }, [])
  useEffect(() => {
    getData()
    return () => { unmounted.current = true }
  }, [])
  const getData = () => {
    axios.get(`danhsach`).then(response => {
      if (!unmounted.current) {
        if (!searching) {
          setKetqua(response.data)
        }
        setList(response.data)
      }
    })
  }
  const handleVangmat = id => {
    const xacnhan = window.confirm('Có chắc chắn muốn đánh dấu vắng mặt không? \nDanh sách này có trong mục "Danh sách tạm thời".')
    if (xacnhan) {
      axios.post(`vangmat`, {id: id}).then(response => {
        if (!unmounted.current) {
          if (response.data === 'ok') {
            getData()
          }
        }
      })
    }
  }

  const fetchDropdownOptions = (key, list) => {
    const kq = filter(list, (item) => item.hoten.toLowerCase().indexOf(key.trim())>-1 || removeVietnameseTones(item.hoten.toLowerCase()).indexOf(removeVietnameseTones(key.trim()))>-1 || item.sodienthoai.indexOf(key.trim())>-1 || item.cmnd.indexOf(key.trim())>-1)
    setKetqua(kq)
  }
  const debounceDropDown = useCallback(debounce((nextValue, list) => fetchDropdownOptions(nextValue, list), 500), [])
  const handleTimkiem = e => {
    setTimkiem(e.currentTarget.value)
    debounceDropDown(e.currentTarget.value.toLowerCase(), list)
    if (e.currentTarget.value.trim()) {
      setSearching(true)
    } else {
      setSearching(false)
    }
  }
  return <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="12" title="Danh sách đã đăng ký" className="text-md-center" />
    </Row>

    <Row>
      <Col>
      <Card small className="mb-4">
          <CardBody>
            <InputGroup seamless>
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <i className="material-icons">search</i>
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                className="navbar-search border-0 no-shadow"
                placeholder="Search..."
                value={timkiem}
                onChange={handleTimkiem}
                disabled={!list}
              />
            </InputGroup>
          </CardBody>
      </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Họ tên
                    </th>
                    <th scope="col" className="border-0">
                      Giới tính
                    </th>
                    <th scope="col" className="border-0">
                      Ngày sinh
                    </th>
                    <th scope="col" className="border-0">
                      CMND
                    </th>
                    <th scope="col" className="border-0">
                      Điện thoại
                    </th>
                    <th scope="col" className="border-0">
                      Ngày đăng ký
                    </th>
                    <th scope="col" className="border-0"></th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {list ? (ketqua.length ? ketqua.map((item, index) => <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle font-weight-bold text-nowrap text-capitalize"><NavLink to={`/chi-tiet/${item.id}`}><strong>{item.hoten}</strong></NavLink></td>
                    <td className="align-middle">{item.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                    <td className="align-middle">{dateFormat(item.ngaysinh, 'dd/mm/yyyy')}</td>
                    <td className="align-middle">{item.cmnd}</td>
                    <td className="align-middle">{item.sodienthoai}</td>
                    <td className="align-middle">{dateFormat(item.created_at, 'HH:MM, dd/mm/yyyy')}</td>
                    <td><NavLink to={`/chi-tiet/${item.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                    <td><Button block theme="warning" outline type="button" onClick={e=>handleVangmat(item.id)}>Vắng mặt</Button></td>
                  </tr>) : <tr><td className="text-center" colSpan="9">No records</td></tr>) : <tr><td className="text-center" colSpan="9"><Spinner animation="border" role="status" /></td></tr>}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
};

export default Danhsach;
