import React, { useEffect, useState, useRef } from "react";
import { Spinner } from 'react-bootstrap'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormInput, FormSelect, FormRadio, Button } from "shards-react";
import dateFormat from "dateformat";
import { useReactToPrint } from 'react-to-print';
import DatePicker from 'react-date-picker';
import Pusher from 'pusher-js'
import { connect } from "react-redux";
import { SESSION_SCOPE } from '../actions/sessionActions';

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import Khaibao from "../components/user-profile-lite/Khaibao";
import Phieuthu from "../components/user-profile-lite/Phieuthu";
import Phieuketqua from "../components/user-profile-lite/Phieuketqua";
import { docso, currencyFormat } from "../utils/cachamchung";
import classNames from "classnames";

const Chitiet = (props) => {
  const unmounted = useRef(false)
  const componentRef = useRef()
  const componentRef2 = useRef()
  const [chinhsua, setChinhsua] = useState(false)
  const [dulieu, setDulieu] = useState()
  const [dulieu2, setDulieu2] = useState()
  const [loading, setLoading] = useState(true)
  const [khaibao, setKhaibao] = useState()
  const [thongtin, setThongtin] = useState({
    id: 0,
    mamau: '',
    ngaylaymau: dateFormat('', 'yyyy-mm-dd'),
    hoten: '',
    cmnd: '',
    ngaysinh: '',
    sodienthoai: '',
    gioitinh: '1',
    quoctich: '84',
    diachi: '',
    thaydoi: false,
    tenthuoc: '',
    giatien: ''
  })
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
    channel.bind('ketqua', data => {
      if (data.id == props.match.params.id) {
        getData(props.match.params.id)
      }
    });
    return () => { pusher.disconnect() }
  }, [])
  useEffect(() => {
    const id = props.match.params.id
    getData(id)
    getSP()
    return () => { unmounted.current = true }
  }, [])
  const getData = (id) => {
    axios.get(`chitietdangky/${id}`).then(response => {
      if (!unmounted.current) {
        setLoading(false)
        if (response.data) {
          setKhaibao(response.data.khaibao)
          setDulieu(response.data)
          response.data.ngaykhaibao = dateFormat(response.data.ngaykhaibao, 'yyyy-mm-dd')
          response.data.ngaysinh2 = new Date(response.data.ngaysinh)
          if (response.data.status == 3) {
            response.data.phanloai = response.data.ketqua.phanloai
            response.data.ngayphanloai = dateFormat(response.data.ketqua.ngayphanloai, 'yyyy-mm-dd')
          }
          setDulieu2(response.data)
          setThongtin({
            ...thongtin,
            id: response.data.id,
            hoten: response.data.hoten,
            cmnd: response.data.cmnd,
            ngaysinh: dateFormat(response.data.ngaysinh, 'yyyy-mm-dd'),
            sodienthoai: response.data.sodienthoai,
            gioitinh: response.data.gioitinh,
            quoctich: response.data.quoctich,
            diachi: response.data.diachi
          })
        } else {
          props.history.replace('/')
        }
      }
    }).catch(err => {
      props.history.replace('/')
    })
  }
  const [spList, setSpList] = useState([])
  const getSP = () => {
    axios.get(`sinhpham`).then(response => {
      if (!unmounted.current) {
        if (response.data) {
          setSpList(response.data)
        }
      }
    })
  }
  const handleLuachonSinhpham = e => {
    const tim = spList.filter(item => item.id == e.currentTarget.value)
    if (tim.length) {
      setThongtin({ ...thongtin, tenthuoc: tim[0].tenthuoc, giatien: tim[0].giatien })
    }
  }
  const handleLuachonSinhpham2 = e => {
    const tim = spList.filter(item => item.id == e.currentTarget.value)
    if (tim.length) {
      setDulieu2({ ...dulieu2, tenthuoc: tim[0].tenthuoc, giatien: tim[0].giatien })
    }
  }
  const handleInputChange = e => {
    setThongtin({ ...thongtin, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleInputChange2 = e => {
    setDulieu2({ ...dulieu2, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // bodyClass: 'A4'
    // onAfterPrint: () => setLoading(false)
  })
  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
    onAfterPrint: () => {
      // setLoading(false)
      axios.post(`inketqua`, { id: dulieu.ketqua.id })
    }
  })
  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    axios.post(`muahang`, thongtin).then(response => {
      if (!unmounted.current) {
        setLoading(false)
        if (response.data) {
          // const id = props.match.params.id
          // getData(id)
          setDulieu(response.data)
          handlePrint()
        } else {
          // setLoading(false)
          window.alert(response.error)
        }
      }
    }).catch(err => {
      setLoading(false)
      window.alert(err)
    })
  }
  const handleDelete = e => {
    if (!loading) {
      if (window.confirm('Bạn có chắc chắn muốn xoá không? Dữ liệu này sẽ không khôi phục nếu xoá.')) {
        setLoading(true)
        axios.post(`xoadangky`, dulieu).then(response => {
          setLoading(false)
          if (response.data) {
            props.history.goBack()
          } else {
            window.alert(response.error)
          }
        }).catch(err => {
          setLoading(false)
          window.alert(err)
        })
      }
    }
  }
  const handleSubmit2 = e => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      axios.post(`capnhatdangky`, { ...dulieu2, ngaysinh: dateFormat(dulieu2.ngaysinh2, 'yyyy-mm-dd'), loai: e.currentTarget.name }).then(response => {
        setLoading(false)
        if (response.data) {
          setDulieu(response.data)
          handleEdit()
        } else {
          window.alert(response.error)
        }
      }).catch(err => {
        setLoading(false)
        window.alert(err)
      })
    }
  }
  const handleEdit = e => {
    setChinhsua(!chinhsua)
  }
  const [thaydoiInfo, setThaydoiInfo] = useState(false)
  const handleEditInfo = e => {
    setThongtin({ ...thongtin, thaydoi: e.currentTarget.checked })
    setThaydoiInfo(e.currentTarget.checked)
  }
  const checkBirthYear = () => {
    const hientai = dateFormat(dulieu2.ngaysinh2, 'yyyy')
    return (((parseInt(dateFormat(new Date(), 'yyyy')) - parseInt(hientai)) > 100) || ((parseInt(dateFormat(new Date(), 'yyyy')) - parseInt(hientai)) < 0)) ? true : false;
  }
  const handleInputChange3 = (e) => {
    setDulieu2({ ...dulieu2, ngaysinh2: e })
  }
  return <React.Fragment>
    <Container fluid className="main-content-container px-4">
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Chi tiết đăng ký" md="12" className="text-md-center" />
      </Row>
      {dulieu ? (chinhsua ? <React.Fragment>
        <Row>
          <Col lg="12" className="mb-4 text-right">
            <Button type="button" onClick={handleEdit}>Cancel</Button>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin khách hàng</h6>
              </CardHeader>
              <CardBody>
                <Form name="khachhang" onSubmit={handleSubmit2}>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label>Họ tên</label>
                      <FormInput
                        required
                        placeholder="Họ tên"
                        name="hoten"
                        value={dulieu2.hoten}
                        onChange={handleInputChange2}
                      />
                    </Col>
                    <Col md="8" className="form-group">
                      <label>CMND</label>
                      <FormInput
                        required
                        placeholder="CMND"
                        name="cmnd"
                        value={dulieu2.cmnd}
                        onChange={handleInputChange2}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label>Số điện thoại</label>
                      <FormInput
                        required
                        maxLength="10"
                        type="tel"
                        placeholder="Số điện thoại"
                        name="sodienthoai"
                        value={dulieu2.sodienthoai}
                        onChange={handleInputChange2}
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label>Giới tính</label>
                      <FormSelect
                        name="gioitinh"
                        value={dulieu2.gioitinh}
                        onChange={handleInputChange2}
                      >
                        <option value="1">Nam</option>
                        <option value="0">Nữ</option>
                        <option value="2">Khác</option>
                      </FormSelect>
                    </Col>
                    <Col md="4" className="form-group">
                      <label>Ngày sinh</label>
                      <DatePicker
                        // name="ngaysinh2"
                        required
                        onChange={handleInputChange3}
                        value={dulieu2.ngaysinh2}
                        clearIcon={null}
                        className={classNames("form-control", checkBirthYear() ? 'is-invalid' : '')}
                        format="dd/MM/y"
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                      {/* <FormInput
                        required
                        placeholder="Ngày sinh"
                        name="ngaysinh"
                        type="date"
                        value={dulieu2.ngaysinh}
                        onChange={handleInputChange2}
                      /> */}
                    </Col>
                    <Col md="5" className="form-group">
                      <label>Quốc tịch</label>
                      <FormSelect
                        name="quoctich"
                        value={dulieu2.quoctich}
                        onChange={handleInputChange2}
                      >
                        <option value="84">Việt Nam</option>
                        <option value="0">Khác</option>
                      </FormSelect>
                    </Col>
                    <Col md="12" className="form-group">
                      <label>Địa chỉ</label>
                      <FormInput
                        required
                        placeholder="Địa chỉ"
                        name="diachi"
                        value={dulieu2.diachi}
                        onChange={handleInputChange2}
                      />
                    </Col>
                  </Row>
                  <Button type="submit" theme="primary" block size="md">Lưu</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            {dulieu.status >= 2 ? <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin sinh phẩm</h6>
              </CardHeader>
              <CardBody>
                <Form name="thongtinlaymau" onSubmit={handleSubmit2}>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label>Chọn sinh phẩm</label>
                      <FormSelect value={""} onChange={handleLuachonSinhpham2}>
                        <option value="">Chọn sinh phẩm</option>
                        {spList.map((item, index) => <option value={item.id} key={index}>{item.tenthuoc} ({item.mota}) / Giá: {currencyFormat(item.giatien)} đ</option>)}
                      </FormSelect>
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Tên sinh phẩm</label>
                      <FormInput
                        required
                        placeholder="Tên sinh phẩm"
                        name="tenthuoc"
                        value={dulieu2.tenthuoc}
                        onChange={handleInputChange2}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Đơn giá</label>
                      <FormInput
                        required
                        type="number"
                        placeholder="Đơn giá"
                        name="giatien"
                        value={dulieu2.giatien}
                        onChange={handleInputChange2}
                      />
                      {dulieu2.giatien != '' && dulieu2.giatien > 0 ? <div className="first-uppercase font-italic font-weight-bold mt-2">{docso(dulieu2.giatien)}</div> : null}
                    </Col>
                    {/* <Col md="6" className="form-group">
                      <label>Mã mẫu</label>
                      <FormInput
                        key="mamau"
                        required
                        placeholder="Mã mẫu"
                        name="mamau"
                        value={dulieu2.mamau}
                        onChange={handleInputChange2}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Ngày lấy mẫu</label>
                      <FormInput
                        key="ngaykhaibao"
                        required
                        type="date"
                        placeholder="Ngày lấy mẫu"
                        name="ngaykhaibao"
                        value={dulieu2.ngaykhaibao}
                        onChange={handleInputChange2}
                      />
                    </Col> */}
                  </Row>
                  <Button type="submit" theme="primary" block size="md">Lưu</Button>
                </Form>
              </CardBody>
            </Card> : null}
            {dulieu.status == 3 ? <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Kết quá xét nghiệm</h6>
              </CardHeader>
              <CardBody>
                <Form name="ketqua" onSubmit={handleSubmit2}>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label><strong>Kết quả xét nghiệm</strong></label>
                      <fieldset className="d-flex justify-content-around">
                        <FormRadio name="phanloai" required value={0}
                          onChange={handleInputChange2}
                          defaultChecked={dulieu2.phanloai == 0}
                        >Âm tính</FormRadio>
                        <span className="ml-5" />
                        <FormRadio name="phanloai" required value={1}
                          onChange={handleInputChange2}
                          defaultChecked={dulieu2.phanloai == 1}
                        >Dương tính</FormRadio>
                      </fieldset>
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Ngày xét nghiệm</label>
                      <FormInput
                        required
                        type="date"
                        placeholder="Ngày xét nghiệm"
                        name="ngayphanloai"
                        value={dulieu2.ngayphanloai}
                        onChange={handleInputChange2}
                      />
                    </Col>
                  </Row>
                  <Button type="submit" theme="primary" block size="md">Lưu</Button>
                </Form>
              </CardBody>
            </Card> : null}
          </Col>
        </Row>
      </React.Fragment> : <React.Fragment>
        <Row>
          <Col lg="12" className="mb-4 text-right">
            <Button type="button" onClick={e => props.history.goBack()}>Trở về</Button>
            {props.auth[SESSION_SCOPE].user.user.role == 'admin' ? <React.Fragment><Button type="button" className="ml-3" onClick={handleEdit}>Chỉnh sửa</Button>
            <Button type="button" onClick={handleDelete} className="ml-3" theme="danger">Xoá</Button></React.Fragment> : null}
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <UserDetails userDetails={dulieu} />
            <Khaibao khaibao={khaibao} />
            <Phieuthu ref={componentRef} dulieu={dulieu} />
          </Col>
          <Col lg="6">
            {dulieu.status < 2 ? <Form onSubmit={handleSubmit}>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Thông tin lấy mẫu</h6>
                </CardHeader>
                <CardBody>
                  <Row form>
                    <Col md="12" className="form-group">
                      <div className="checkbox">
                        <label className="d-flex align-items-center mb-0"><input type="checkbox" className="mr-1" onChange={handleEditInfo} /> Thay đổi thông tin khách hàng.</label>
                      </div>
                    </Col>
                    {thaydoiInfo ? <React.Fragment>
                      <Col md="8" className="form-group">
                        <label>Họ tên</label>
                        <FormInput
                          required
                          placeholder="Họ tên"
                          name="hoten"
                          value={thongtin.hoten}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label>Ngày sinh</label>
                        <FormInput
                          type="date"
                          required
                          placeholder="Ngày sinh"
                          name="ngaysinh"
                          value={thongtin.ngaysinh}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label>CMND</label>
                        <FormInput
                          required
                          placeholder="CMND"
                          name="cmnd"
                          value={thongtin.cmnd}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label>Số điện thoại</label>
                        <FormInput
                          required
                          type="tel"
                          placeholder="Số điện thoại"
                          name="sodienthoai"
                          value={thongtin.sodienthoai}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col md="3" className="form-group">
                        <label>Giới tính</label>
                        <FormSelect
                          name="gioitinh"
                          value={thongtin.gioitinh}
                          onChange={handleInputChange}
                        >
                          <option value="1">Nam</option>
                          <option value="0">Nữ</option>
                          <option value="2">Khác</option>
                        </FormSelect>
                        {/* <FormInput
                          required
                          placeholder="Giới tính"
                          name="gioitinh"
                          value={thongtin.gioitinh}
                          onChange={handleInputChange}
                        /> */}
                      </Col>
                      <Col md="9" className="form-group">
                        <label>Quốc tịch</label>
                        <FormSelect
                          name="quoctich"
                          value={thongtin.quoctich}
                          onChange={handleInputChange}
                        >
                          <option value="84">Việt Nam</option>
                          <option value="0">Khác</option>
                        </FormSelect>
                        {/* <FormInput
                          required
                          placeholder="Quốc tịch"
                          name="quoctich"
                          value={thongtin.quoctich}
                          onChange={handleInputChange}
                        /> */}
                      </Col>
                      <Col md="12" className="form-group">
                        <label>Địa chỉ</label>
                        <FormInput
                          required
                          placeholder="Địa chỉ"
                          name="diachi"
                          value={thongtin.diachi}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </React.Fragment> : null}
                  </Row>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label>Mã mẫu</label>
                      <FormInput
                        required
                        placeholder="Mã mẫu"
                        name="mamau"
                        value={thongtin.mamau}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Ngày lấy mẫu</label>
                      <FormInput
                        required
                        type="date"
                        placeholder="Ngày lấy mẫu"
                        name="ngaylaymau"
                        value={thongtin.ngaylaymau}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md="12" className="form-group">
                      <label>Chọn sinh phẩm</label>
                      <FormSelect value={""} onChange={handleLuachonSinhpham}>
                        <option value="">Chọn sinh phẩm</option>
                        {spList.map((item, index) => <option value={item.id} key={index}>{item.tenthuoc} ({item.mota}) / Giá: {currencyFormat(item.giatien)} đ</option>)}
                      </FormSelect>
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Tên sinh phẩm</label>
                      <FormInput
                        required
                        placeholder="Tên sinh phẩm"
                        name="tenthuoc"
                        value={thongtin.tenthuoc}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>Đơn giá</label>
                      <FormInput
                        required
                        type="number"
                        placeholder="Đơn giá"
                        name="giatien"
                        value={thongtin.giatien}
                        onChange={handleInputChange}
                      />
                      {thongtin.giatien != '' && thongtin.giatien > 0 ? <div className="first-uppercase font-italic font-weight-bold mt-2">{docso(thongtin.giatien)}</div> : null}
                    </Col>
                  </Row>
                  <Button type="submit" theme="primary" block size="md">Lưu và In</Button>
                </CardBody>
              </Card>
            </Form> : <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin lấy mẫu</h6>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="6" className="form-group">
                    <label>Mã mẫu</label>
                    <FormInput
                      // required
                      readOnly
                      placeholder="Mã mẫu"
                      defaultValue={dulieu.mamau}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Ngày lấy mẫu</label>
                    <FormInput
                      // required
                      // type="date"
                      readOnly
                      placeholder="Ngày lấy mẫu"
                      defaultValue={dateFormat(dulieu.ngaykhaibao, 'dd/mm/yyyy')}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Tên sinh phẩm</label>
                    <FormInput
                      // required
                      readOnly
                      placeholder="Tên sinh phẩm"
                      defaultValue={dulieu.tenthuoc}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Giá tiền</label>
                    <FormInput
                      // required
                      // type="date"
                      readOnly
                      placeholder="Giá tiền"
                      defaultValue={dulieu.giatien}
                    // defaultValue={dateFormat(dulieu.ngaykhaibao, 'dd/mm/yyyy')}
                    />
                    <div className="first-uppercase font-italic">{docso(dulieu.giatien)}</div>
                  </Col>
                </Row>
                <Button type="button" onClick={handlePrint} theme="primary" block size="md">In lại phiếu thu</Button>
              </CardBody>
            </Card>}
            {dulieu.ketqua && <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Kết quả lấy mẫu</h6>
              </CardHeader>
              <CardBody>
                <Phieuketqua ref={componentRef2} dulieu={dulieu} />
                <Row form>
                  <Col md="6" className="form-group">
                    <label>Kết quả</label>
                    <FormInput
                      // required
                      readOnly
                      placeholder="Kết quả"
                      defaultValue={dulieu.ketqua.phanloai == 1 ? 'Dương tính' : 'Âm tính'}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Ngày kết quả</label>
                    <FormInput
                      // required
                      // type="date"
                      readOnly
                      placeholder="Ngày kết quả"
                      defaultValue={dateFormat(dulieu.ketqua.ngayphanloai, 'dd/mm/yyyy')}
                    />
                  </Col>
                </Row>
                <Button type="button" onClick={handlePrint2} theme="primary" block size="md">In kết quả ({dulieu.ketqua.print_count} lần)</Button>
              </CardBody>
            </Card>}
          </Col>
        </Row>
      </React.Fragment>) : null}
    </Container>
  </React.Fragment>
};

const mapStateToProps = state => {
  return {
    auth: state
  };
};
export default connect(mapStateToProps)(Chitiet);
// export default Chitiet;
