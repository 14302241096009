export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const SESSION_SCOPE = 'session';

export const login = (userData) => dispatch =>
  dispatch({
    type: SESSION_LOGIN,
    payload: userData
  });

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  });
