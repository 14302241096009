import React, { useState, useEffect } from "react"
import { Modal, Spinner } from 'react-bootstrap'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormInput, FormSelect, Button, FormRadio, FormCheckbox } from "shards-react";
import dateFormat from "dateformat";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import PageTitle from "../components/common/PageTitle";
import axios from "../utils/axios";
import { currencyFormat } from "../utils/cachamchung";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const Themmoi = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [danhsach, setDanhsach] = useState([])
  const [dulieu, setDulieu] = useState({
    congty: '',
    diachi: '',
    lienhe: '',
    phone: '',
    thuchien: '',
    baocao: '',
    ngaythuchien: dateFormat(new Date(), 'yyyy-mm-dd'),
    tenthuoc: '',
    giatien: ''
  })
  const [spList, setSpList] = useState([])
  useEffect(() => {
    let unmounted = false
    setLoading(true)
    axios.get(`sinhpham`).then(response => {
      if (!unmounted) {
        setLoading(false)
        if (response.data) {
          setSpList(response.data)
        }
      }
    }).catch(err => setLoading(false))
    return () => { unmounted = true }
  }, [])
  const handleLuachonSinhpham = e => {
    const tim = spList.filter(item => item.id == e.currentTarget.value)
    if (tim.length) {
      setDulieu({ ...dulieu, tenthuoc: tim[0].tenthuoc, giatien: tim[0].giatien })
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!loading) {
      if (dulieu.congty) {
        setLoading(true)
        axios.post(`dangky3`, {
          dulieu: dulieu,
          danhsach: danhsach
        }).then(response => {
          setLoading(false)
          if (response.id) {
            props.history.push(`/chi-tiet-cong-ty/${response.id}`)
          } else {
            window.alert(response.error)
          }
        }).catch(err => {
          setLoading(false)
          window.alert(err)
        })
      } else {
        window.alert('Hãy điền đầy đủ thông tin công ty.')
      }
    }
    // console.log(dulieu);
  }
  const handleInputChange = (e) => {
    setDulieu({ ...dulieu, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handlePatse = e => {
    navigator.clipboard.readText()
      .then(text => {
        let splitted = text.split("\n")
        let kq = []
        for (let i = 0; i < splitted.length; i++) {
          const tmp = splitted[i].split("\t")
          if (tmp.length >= 4) {
            kq.push(tmp)
          }
        }
        // if (kq.length) {
        //   let employees = kq.sort((a, b) => {
        //     let fa = a[0].toLowerCase(),
        //       fb = b[0].toLowerCase();

        //     if (fa < fb) {
        //       return -1;
        //     }
        //     if (fa > fb) {
        //       return 1;
        //     }
        //     return 0;
        //   });
        // }
        setDanhsach([...danhsach, ...kq])
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
  const handleSubmit2 = e => {
    e.preventDefault()
    setDanhsach([...danhsach, [
      thongtin.hoten,
      thongtin.gioitinh,
      thongtin.namsinh,
      thongtin.diachi
    ]])
    setShow(false)
    setThongtin({
      ...thongtin,
      hoten: '',
      gioitinh: '1',
      namsinh: '',
      diachi: ''
    })
  }
  const [thongtin, setThongtin] = useState({
    hoten: '',
    gioitinh: '1',
    namsinh: '',
    diachi: ''
  })
  const handleInputChange2 = (e) => {
    setThongtin({ ...thongtin, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleXoa = i => {
    if (window.confirm('Có chắc chắn muốn xoá không?')) {
      const newDanhsach = danhsach.filter((item, index) => i != index)
      setDanhsach(newDanhsach)
    }
  }
  const [thongtin2, setThongtin2] = useState({
    sheet: 1,
    beginRow: 11,
    hoten: 2,
    gioitinh: 3,
    namsinh: 4,
    diachi: 5,
    danhsach: []
  })
  const handleInputChange3 = (e) => {
    setThongtin2({ ...thongtin2, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleSubmit3 = e => {
    e.preventDefault()
    setDanhsach([...danhsach, ...thongtin2.danhsach])
    setShow2(false)
    setThongtin2({ ...thongtin2, danhsach: [] })
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLoading(true)
      var formData = new FormData()
      formData.append('file', event.target.files[0])
      formData.append('sheet', thongtin2.sheet)
      formData.append('beginRow', thongtin2.beginRow)
      formData.append('hoten', thongtin2.hoten)
      formData.append('gioitinh', thongtin2.gioitinh)
      formData.append('namsinh', thongtin2.namsinh)
      formData.append('diachi', thongtin2.diachi)
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}export`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        setLoading(false)
        if (response.data) {
          setThongtin2({ ...thongtin2, danhsach: response.data })
        } else {
          window.alert(response.error)
        }
      }).catch((response) => {
        setLoading(false)
      });
    }
  }
  /////////////////////////
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    setIsLoading(true);

    axios.get(`${process.env.REACT_APP_API_URL}timkiem_congty?q=${query}`).then(response => {
      const options = response.data.map((i) => i);
      setOptions(options);
      setIsLoading(false);
    }).catch(err => setIsLoading(false))

    // fetch(`https://api.github.com/search/users?q=${query}+in:login&page=1&per_page=50`)
    //   .then((resp) => resp.json())
    //   .then(({ items }) => {
    //     const options = items.map((i) => i/*({
    //       avatar_url: i.avatar_url,
    //       id: i.id,
    //       login: i.login,
    //     })*/);

    //     setOptions(options);
    //     setIsLoading(false);
    //   });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;
  /////////////////////////
  return <React.Fragment>
    <Modal show={show} onHide={e => setShow(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Nhập thông tin nhân sự</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit2}>
        <Modal.Body className="p-4">
          <Row form>
            <Col md="12" className="form-group">
              <label>Họ tên</label>
              <FormInput
                required
                name="hoten"
                value={thongtin.hoten}
                onChange={handleInputChange2}
              />
            </Col>
            <Col md="6" className="form-group">
              <label>Giới tính</label>
              <FormSelect name="gioitinh" value={thongtin.gioitinh} onChange={handleInputChange2}>
                <option value="1">Nam</option>
                <option value="0">Nữ</option>
              </FormSelect>
            </Col>
            <Col md="6" className="form-group">
              <label>Năm sinh</label>
              <FormInput
                required
                maxLength={4}
                name="namsinh"
                value={thongtin.namsinh}
                onChange={handleInputChange2}
              />
            </Col>
            <Col md="12">
              <label>Địa chỉ</label>
              <FormInput
                required
                name="diachi"
                value={thongtin.diachi}
                onChange={handleInputChange2}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer><Button type="submit">Lưu</Button></Modal.Footer>
      </Form>
    </Modal>
    <Modal size="lg" show={show2} onHide={e => setShow2(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Nhập thông tin từ tập tin Excel</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit3}>
        <Modal.Body className="p-4">
          {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
          <Row form>
            <Col md="12" className="form-group">
              <input type="file" onChange={onImageChange} />
            </Col>
            <Col md="2" className="form-group">
              <label>Sheet</label>
              <FormInput
                required
                type="number"
                name="sheet"
                value={thongtin2.sheet}
                onChange={handleInputChange3}
              />
            </Col>
            <Col md="2" className="form-group">
              <label>Bắt đầu hàng</label>
              <FormInput
                required
                type="number"
                name="beginRow"
                value={thongtin2.beginRow}
                onChange={handleInputChange3}
              />
            </Col>
            {/* </Row>
          <Row form> */}
            <Col md="2" className="form-group">
              <label>Họ tên</label>
              <FormInput
                required
                type="number"
                name="hoten"
                value={thongtin2.hoten}
                onChange={handleInputChange3}
              />
            </Col>
            <Col md="2" className="form-group">
              <label>Giới tính</label>
              <FormInput
                required
                type="number"
                name="gioitinh"
                value={thongtin2.gioitinh}
                onChange={handleInputChange3}
              />
            </Col>
            <Col md="2" className="form-group">
              <label>Năm sinh</label>
              <FormInput
                required
                type="number"
                name="namsinh"
                value={thongtin2.namsinh}
                onChange={handleInputChange3}
              />
            </Col>
            <Col md="2" className="form-group">
              <label>Địa chỉ</label>
              <FormInput
                required
                type="number"
                name="diachi"
                value={thongtin2.diachi}
                onChange={handleInputChange3}
              />
            </Col>
          </Row>
          {thongtin2.danhsach.length > 0 && <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-bottom-0 text-center">
                    STT
                  </th>
                  <th scope="col" className="border-bottom-0">
                    Họ tên
                  </th>
                  <th scope="col" className="border-bottom-0 text-center">
                    Giới tính
                  </th>
                  <th scope="col" className="border-bottom-0 text-center">
                    Năm sinh
                  </th>
                  <th scope="col" className="border-bottom-0">
                    Địa chỉ
                  </th>
                  {/* <th scope="col" className="border-bottom-0"></th> */}
                </tr>
              </thead>
              <tbody>
                {thongtin2.danhsach.map((item, index) => <tr key={index}>
                  <td className="align-middle text-center">{index + 1}</td>
                  <td className="align-middle font-weight-bold text-nowrap text-capitalize"><strong>{item[0]}</strong></td>
                  <td className="align-middle text-center">{item[1] == 1 ? 'Nam' : 'Nữ'}</td>
                  <td className="align-middle text-center">{item[2]}</td>
                  <td className="align-middle">{item[3]}</td>
                  {/* <td className="align-middle"><Button type="button" size="sm" block outline theme="danger">Xoá</Button></td> */}
                </tr>)}
              </tbody>
            </table>
          </div>}
        </Modal.Body>
        {thongtin2.danhsach.length > 0 && <Modal.Footer><Button type="submit">Insert</Button></Modal.Footer>}
      </Form>
    </Modal>
    <Container fluid className="main-content-container px-4 pb-4">
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="12" title="Công ty đăng ký xét nghiệm Covid-19" className="text-md-center" />
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg="6">
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin công ty</h6>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="12" className="form-group">
                    <label>Tên công ty</label>
                    <AsyncTypeahead
                      // allowNew
                      highlightOnlyResult
                      onChange={e => {
                        // console.log(e);
                        if (e.length) {
                          setDulieu({ ...dulieu, congty: e[0].tencongty, diachi: e[0].diachi, phone: e[0].dienthoai, lienhe: e[0].lienhe })
                        } else {
                          setDulieu({ ...dulieu, congty: '' })
                        }
                      }}
                      // onInputChange={e=>console.log('input', e)}
                      onBlur={e => {
                        // console.log('blud', dulieu.congty, e.target.attributes.value.value)
                        if (dulieu.congty == '') {
                          setDulieu({ ...dulieu, congty: e.target.attributes.value.value })
                        }
                      }}
                      shouldSelect
                      filterBy={filterBy}
                      id="async-example"
                      isLoading={isLoading}
                      labelKey="tencongty"
                      minLength={2}
                      onSearch={handleSearch}
                      options={options}
                      // placeholder="Search for a Github user..."
                      renderMenuItemChildren={(option, props) => (
                        <React.Fragment>
                          {/* <img
                            alt={option.login}
                            src={option.avatar_url}
                            style={{
                              height: '24px',
                              marginRight: '10px',
                              width: '24px',
                            }}
                          /> */}
                          <span>{option.tencongty} - Địa chỉ: {option.diachi}</span>
                        </React.Fragment>
                      )}
                    />
                    {/* <FormInput
                      required
                      placeholder="Tên công ty"
                      name="congty"
                      value={dulieu.congty}
                      onChange={handleInputChange}
                    /> */}
                  </Col>
                  <Col md="12" className="form-group">
                    <label>Địa chỉ</label>
                    <FormInput
                      placeholder="Địa chỉ"
                      name="diachi"
                      value={dulieu.diachi}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Người liên hệ</label>
                    <FormInput
                      placeholder="Người liên hệ"
                      name="lienhe"
                      value={dulieu.lienhe}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Điện thoại</label>
                    <FormInput
                      maxLength="10"
                      type="tel"
                      placeholder="Điện thoại"
                      name="phone"
                      value={dulieu.phone}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin xét nghiệm</h6>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="6" className="form-group">
                    <label>Người thực hiện test</label>
                    <FormInput
                      placeholder="Người thực hiện test"
                      name="thuchien"
                      value={dulieu.thuchien}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Người báo cáo</label>
                    <FormInput
                      placeholder="Người báo cáo"
                      name="baocao"
                      value={dulieu.baocao}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label>Ngày thực hiện</label>
                    <FormInput
                      type="date"
                      placeholder="Ngày thực hiện"
                      name="ngaythuchien"
                      value={dulieu.ngaythuchien}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="8" className="form-group">
                    <label>Chọn sinh phẩm</label>
                    <FormSelect value={""} onChange={handleLuachonSinhpham}>
                      <option value="">Chọn sinh phẩm</option>
                      {spList.map((item, index) => <option value={item.id} key={index}>{item.tenthuoc} ({item.mota}) / Giá: {currencyFormat(item.giatien)} đ</option>)}
                    </FormSelect>
                  </Col>
                  <Col md="8" className="form-group">
                    <label>Tên sinh phẩm</label>
                    <FormInput
                      required
                      placeholder="Tên sinh phẩm"
                      name="tenthuoc"
                      value={dulieu.tenthuoc}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label>Đơn giá</label>
                    <FormInput
                      required
                      type="number"
                      placeholder="Đơn giá"
                      name="giatien"
                      value={dulieu.giatien}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Nhân sự công ty đăng ký test</h6>
              </CardHeader>
              <CardBody className="danhsach-congty">
                <Row>
                  <Col className="mb-3 text-right">
                    <Button type="button" size="sm" onClick={handlePatse}>Dán từ Excel</Button>
                    <Button type="button" size="sm" onClick={e => setShow(true)} className="ml-3">Thêm thủ công</Button>
                    <Button type="button" size="sm" className="ml-3" onClick={e => setShow2(true)}>Upload file</Button>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-bottom-0 text-center">
                          STT
                        </th>
                        <th scope="col" className="border-bottom-0">
                          Họ tên
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          Giới tính
                        </th>
                        <th scope="col" className="border-bottom-0 text-center">
                          Năm sinh
                        </th>
                        <th scope="col" className="border-bottom-0">
                          Địa chỉ
                        </th>
                        <th scope="col" className="border-bottom-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {danhsach.map((item, index) => <tr key={index}>
                        <td className="align-middle text-center">{index + 1}</td>
                        <td className="align-middle font-weight-bold text-nowrap text-capitalize"><strong>{item[0]}</strong></td>
                        <td className="align-middle text-center">{item[1] == 1 ? 'Nam' : 'Nữ'}</td>
                        <td className="align-middle text-center">{item[2]}</td>
                        <td className="align-middle">{item[3]}</td>
                        <td className="align-middle"><Button type="button" onClick={e => handleXoa(index)} size="sm" block outline theme="danger">Xoá</Button></td>
                      </tr>)}
                      {danhsach.length == 0 && <tr><td colSpan="6" className="text-center">Copy dữ liệu bên Excel và click nút "Dán từ Excel" phía trên.</td></tr>}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
            {danhsach.length > 0 && <Button type="submit" theme="primary" block size="lg">Lưu</Button>}
          </Col>
        </Row>
      </Form>
    </Container>
  </React.Fragment>
};

export default Themmoi;
