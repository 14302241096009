import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Form, ListGroup, ListGroupItem, FormInput, FormSelect, Button } from "shards-react";
import { Spinner } from 'react-bootstrap'

import PageTitle from "../components/common/PageTitle";
import axios from "../utils/axios";

const ChitietNguoidung = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [error2, setError2] = useState()
    const [dulieu, setDulieu] = useState()
    const [dulieu2, setDulieu2] = useState()
    const [dulieu3, setDulieu3] = useState({
        id: props.match.params.id,
        newPassword: '',
        confirmPassword: ''
    })
    const handleSubmit = e => {
        e.preventDefault()
        if (!loading) {
            setLoading(true)
            setError(null)
            axios.post(`capnhatnguoidung`, dulieu).then(response => {
                setLoading(false)
                if (response.data) {
                    //
                } else {
                    setError(response.error)
                }
            }).catch(err => { setLoading(false); setError(err.message) })
        }
    }
    const handleSubmit2 = e => {
        e.preventDefault()
        if (!loading) {
            setLoading(true)
            setError2(null)
            axios.post(`doimatkhau`, dulieu3).then(response => {
                setLoading(false)
                if (response.data) {
                    //
                } else {
                    setError2(response.error)
                }
            }).catch(err => { setLoading(false); setError2(err.message) })
        }
    }
    const handleInputChange = e => {
        setDulieu({ ...dulieu, [e.currentTarget.name]: e.currentTarget.value })
    }
    const handleInputChange2 = e => {
        setDulieu3({ ...dulieu3, [e.currentTarget.name]: e.currentTarget.value })
    }
    useEffect(() => {
        axios.get(`chitietnguoidung/${props.match.params.id}`).then(response => {
            if (response.data) {
                setDulieu2(response.data)
                setDulieu(response.data)
            } else {
                props.history.push('/nguoi-dung')
            }
        }).catch(err => props.history.push('/nguoi-dung'))
    }, [])
    return <Container fluid className="main-content-container px-4">
        {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
        <Row noGutters className="page-header py-4">
            <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        {dulieu ? <Row>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-center">
                        <div className="mb-3 mx-auto">
                            <img
                                className="rounded-circle"
                                src="https://xetnghiemtaman.com/logo2.png"
                                width="110"
                            />
                        </div>
                        <h4 className="mb-0 text-capitalize">{dulieu2.name}</h4>
                        <span className="text-muted d-block mb-0">{dulieu2.email}</span>
                        <span className="text-muted d-block mb-2">Role: {dulieu2.role}</span>
                    </CardHeader>
                </Card>
            </Col>
            <Col lg="8">
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Account Details</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col>
                                    <Form name="account" onSubmit={handleSubmit}>
                                        <Row form>
                                            {/* First Name */}
                                            <Col md="6" className="form-group">
                                                <label htmlFor="feFirstName">Name</label>
                                                <FormInput
                                                    required
                                                    id="feFirstName"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={dulieu.name}
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                            {/* Email */}
                                            <Col md="6" className="form-group">
                                                <label htmlFor="feEmail">Email</label>
                                                <FormInput
                                                    required
                                                    type="email"
                                                    id="feEmail"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    value={dulieu.email}
                                                    onChange={handleInputChange}
                                                    autoComplete="email"
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Role</label>
                                                <FormSelect
                                                    name="role"
                                                    value={dulieu.role}
                                                    onChange={handleInputChange}>
                                                    <option value="thungan">Thu ngân</option>
                                                    <option value="lab">Lab</option>
                                                    <option value="admin">Admin</option>
                                                </FormSelect>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Active</label>
                                                <FormSelect
                                                    name="active"
                                                    value={dulieu.active}
                                                    onChange={handleInputChange}>
                                                    <option value="1">Active</option>
                                                    <option value="0">Disable</option>
                                                </FormSelect>
                                            </Col>
                                            {error ? <Col md="12" className="form-group text-danger">{error}</Col> : null}
                                        </Row>
                                        <Button type="submit" theme="accent">Update Account</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Đổi mật khẩu</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col>
                                    <Form name="changepassword" onSubmit={handleSubmit2}>
                                        <Row form>
                                            <Col md="6" className="form-group">
                                                <label>Mật khẩu mới</label>
                                                <FormInput
                                                    required
                                                    type="password"
                                                    name="newPassword"
                                                    value={dulieu3.newPassword}
                                                    onChange={handleInputChange2}
                                                />
                                            </Col>
                                            {/* Password */}
                                            <Col md="6" className="form-group">
                                                <label>Xác thực lại mật khẩu</label>
                                                <FormInput
                                                    required
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={dulieu3.confirmPassword}
                                                    onChange={handleInputChange2}
                                                />
                                            </Col>
                                            {error2 ? <Col md="12" className="form-group text-danger">{error2}</Col> : null}
                                        </Row>
                                        <Button type="submit" theme="accent">Đổi mật khẩu</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </Row> : null}
    </Container>
};

export default ChitietNguoidung;
