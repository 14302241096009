import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormRadio,
  FormInput,
  FormCheckbox
} from "shards-react";
import dateFormat from "dateformat";

const Khaibao = ({ khaibao }) => (
  <Card small className="mb-3">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Khai báo y tế</h6>
      <p className="m-0">Trong vòng 14 ngày qua</p>
    </CardHeader>
    <CardBody>
      <Row form>
        <Col md="12">
          <label><strong>1. Có về từ nước ngoài</strong></label>
          <fieldset className="d-flex">
            <FormRadio name="loai1" disabled defaultChecked={khaibao.loai1.value == 1}>Có</FormRadio>
            <span className="ml-5" />
            <FormRadio name="loai1" disabled defaultChecked={khaibao.loai1.value != 1}>Không</FormRadio>
          </fieldset>
        </Col>
      </Row>
      {khaibao.loai1.value == 1 ? <Row form>
        <Col md="6" className="form-group">
          <label>Nước đã lưu trú</label>
          <FormInput
            placeholder="Nước đã lưu trú"
            name="loai1.nuocluutru"
            defaultValue={khaibao.loai1.nuocluutru}
          />
        </Col>
        <Col md="6" className="form-group">
          <label>Nước quá cảnh</label>
          <FormInput
            placeholder="Nước quá cảnh"
            name="loai1.nuocquacanh"
            defaultValue={khaibao.loai1.nuocquacanh}
          />
        </Col>
        <Col md="8" className="form-group">
          <label>Thành phố / Tỉnh</label>
          <FormInput
            placeholder="Thành phố / Tỉnh"
            name="loai1.tinhthanh"
            defaultValue={khaibao.loai1.tinhthanh}
          />
        </Col>
        <Col md="4" className="form-group">
          <label>Ngày về Việt Nam</label>
          <FormInput
            // type="date"
            placeholder="Ngày về Việt Nam"
            name="loai1.ngayve"
            defaultValue={dateFormat(khaibao.loai1.ngayve, 'dd/mm/yyyy')}
          />
        </Col>
      </Row> : null}
      <Row form>
        <Col md="12">
          <label><strong>2. Có sống hoặc đã đến nơi có dịch lưu hành</strong></label>
          <fieldset className="d-flex">
            <FormRadio name="loai2" disabled defaultChecked={khaibao.loai2.value == 1}>Có</FormRadio>
            <span className="ml-5" />
            <FormRadio name="loai2" disabled defaultChecked={khaibao.loai2.value != 1}>Không</FormRadio>
          </fieldset>
        </Col>
      </Row>
      {khaibao.loai2.value == 1 ? <Row form>
        <Col md="4" className="form-group">
          <label>Từ ngày</label>
          <FormInput
            placeholder="Từ ngày"
            // type="date"
            name="loai2.tungay"
            defaultValue={khaibao.loai2.tungay ? dateFormat(khaibao.loai2.tungay, 'dd/mm/yyyy') : '-'}
          />
        </Col>
        <Col md="4" className="form-group">
          <label>Đến ngày</label>
          <FormInput
            placeholder="Đến ngày"
            // type="date"
            name="loai2.denngay"
            defaultValue={khaibao.loai2.denngay ? dateFormat(khaibao.loai2.denngay, 'dd/mm/yyyy') : '-'}
          />
        </Col>
      </Row> : null}
      <Row form>
        <Col md="12">
          <label><strong>3. Có tiếp xúc với người được xác định hoặc nghi ngờ nhiễm COVID-19</strong></label>
          <fieldset className="d-flex">
            <FormRadio name="loai3" disabled defaultChecked={khaibao.loai3.value == 1}>Có</FormRadio>
            <span className="ml-5" />
            <FormRadio name="loai3" disabled defaultChecked={khaibao.loai3.value != 1}>Không</FormRadio>
          </fieldset>
        </Col>
      </Row>
      {khaibao.loai3.value == 1 ? <Row form>
        <Col md="4" className="form-group">
          <label>Ngày tiếp xúc</label>
          <FormInput
            placeholder="Ngày tiếp xúc"
            // type="date"
            name="loai3.ngaytiepxuc"
            defaultValue={khaibao.loai3.ngaytiepxuc ? dateFormat(khaibao.loai3.ngaytiepxuc, 'dd/mm/yyyy') : '-'}
          />
        </Col>
      </Row> : null}
      <Row form>
        <Col md="12">
          <label><strong>4. Có tiếp xúc với người từ nước ngoài về (người Việt Nam hoặc người nước ngoài)</strong></label>
          <fieldset className="d-flex">
            <FormRadio name="loai4" disabled defaultChecked={khaibao.loai4.value == 1}>Có</FormRadio>
            <span className="ml-5" />
            <FormRadio name="loai4" disabled defaultChecked={khaibao.loai4.value != 1}>Không</FormRadio>
          </fieldset>
        </Col>
      </Row>
      {khaibao.loai4.value == 1 ? <Row form>
        <Col md="4" className="form-group">
          <label>Ngày tiếp xúc</label>
          <FormInput
            placeholder="Ngày tiếp xúc"
            // type="date"
            name="loai4.ngaytiepxuc"
            defaultValue={khaibao.loai4.ngaytiepxuc ? dateFormat(khaibao.loai4.ngaytiepxuc, 'dd/mm/yyyy') : '-'}
          />
        </Col>
      </Row> : null}
      <Row form>
        <Col md="4">
          <label><strong>5. Có triệu chứng</strong></label>
        </Col>
        <Col md="3">
          <fieldset>
            <FormCheckbox name="loai5.ho" defaultChecked={khaibao.loai5.ho}>Ho</FormCheckbox>
            <FormCheckbox name="loai5.sot" defaultChecked={khaibao.loai5.sot}>Sốt</FormCheckbox>
            <FormCheckbox name="loai5.khotho" defaultChecked={khaibao.loai5.khotho}>Khó thở</FormCheckbox>
          </fieldset>
        </Col>
        <Col md="5" className="form-group">
          <fieldset>
            <FormCheckbox name="loai5.dauhong" defaultChecked={khaibao.loai5.dauhong}>Đau họng</FormCheckbox>
            <FormCheckbox name="loai5.khac" defaultChecked={khaibao.loai5.khac}>Khác</FormCheckbox>
            <FormCheckbox name="loai5.khong" defaultChecked={khaibao.loai5.khong}>Không có triệu chứng</FormCheckbox>
          </fieldset>
        </Col>
      </Row>
      <Row form>
        <Col md="4">
          <label><strong>6. Có bệnh mạn tính</strong></label>
        </Col>
        <Col md="3">
          <fieldset>
            <FormCheckbox name="loai6.gan" defaultChecked={khaibao.loai6.gan}>Gan</FormCheckbox>
            <FormCheckbox name="loai6.than" defaultChecked={khaibao.loai6.than}>Thận</FormCheckbox>
            <FormCheckbox name="loai6.phoi" defaultChecked={khaibao.loai6.phoi}>Phổi</FormCheckbox>
            <FormCheckbox name="loai6.timmach" defaultChecked={khaibao.loai6.timmach}>Tim mạch</FormCheckbox>
          </fieldset>
        </Col>
        <Col md="5" className="form-group">
          <fieldset>
            <FormCheckbox name="loai6.caohuyetap" defaultChecked={khaibao.loai6.caohuyetap}>Cao huyết áp</FormCheckbox>
            <FormCheckbox name="loai6.tieuduong" defaultChecked={khaibao.loai6.tieuduong}>Tiểu đường</FormCheckbox>
            <FormCheckbox name="loai6.khac" defaultChecked={khaibao.loai6.khac}>Khác</FormCheckbox>
            <FormCheckbox name="loai6.khong" defaultChecked={khaibao.loai6.khong}>Không có bệnh mạn tính</FormCheckbox>
          </fieldset>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default Khaibao;
