import React, {
    useEffect, useState, useRef,
    // useCallback
} from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import {
    Container, Row, Col, Card, CardBody,
    Form,
    // InputGroupAddon,
    // InputGroupText,
    Button,
    FormInput,
    CardHeader
} from "shards-react";
// import Pusher from 'pusher-js'
// import { debounce, filter } from 'lodash'

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import MyPagination from "../components/common/MyPagination";
import { currencyFormat } from "../utils/cachamchung";
// import { removeVietnameseTones } from '../utils/cachamchung'

const Thongke = (props) => {
    const unmounted = useRef(false)
    // const [list, setList] = useState()
    const [ketqua, setKetqua] = useState({
        total: 0,
        currentPage: 0,
        from: 0,
        to: 0,
        tungay: null,
        denngay: null,
        tongtien: 0
    })
    // const [timkiem, setTimkiem] = useState('')
    const [thongtin, setThongtin] = useState({
        tungay: dateFormat(new Date(), 'yyyy-mm-dd'),
        denngay: dateFormat(new Date(), 'yyyy-mm-dd')
    })
    const [danhsach, setDanhsach] = useState()
    const [totPages, setTotPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    // const [thongtin2, setThongtin2] = useState()
    // useEffect(() => {
    //     const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    //         cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    //         encrypted: true
    //     });
    //     const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
    //     channel.bind('ketqua', data => {
    //         getData(currentPage)
    //     });
    //     return () => { pusher.disconnect() }
    // }, [])
    useEffect(() => {
        // getData(1)
        return () => { unmounted.current = true }
    }, [])
    useEffect(() => {
        // console.log(props.match.params.dieukien);
        if (props.match.params.dieukien) {
            const dieukien = props.match.params.dieukien.split(',')
            // console.log(dieukien);
            if (dieukien.length === 3) {
                const batdau = dieukien[0]
                const ketthuc = dieukien[1]
                const trang = parseInt(dieukien[2])
                setThongtin({
                    tungay: batdau,
                    denngay: ketthuc,
                })
                // setThongtin2({
                //     tungay: batdau,
                //     denngay: ketthuc,
                //     page: trang
                // })
                setCurrentPage(trang)
                getData3({
                    tungay: batdau,
                    denngay: ketthuc,
                    page: trang
                })
            }
        }
    }, [props])
    const getData3 = data => {
        axios.post(`danhsachthongke?page=${data.page}`, data).then(response => {
            if (!unmounted.current) {
                setDanhsach(response.data)
                setKetqua({
                    ...ketqua,
                    total: response.data.length,
                    // currentPage: response.data.current_page,
                    // from: response.data.from,
                    // to: response.data.to,
                    tungay: data.tungay,
                    denngay: data.denngay,
                    tongtien: response.tongtien
                })
                // console.log(response);
                setTotPages(response.data.last_page)
                // setDanhsach(response.data.data)
                // setKetqua({
                //     ...ketqua,
                //     total: response.data.total,
                //     currentPage: response.data.current_page,
                //     from: response.data.from,
                //     to: response.data.to,
                //     tungay: data.tungay,
                //     denngay: data.denngay,
                //     tongtien: response.tongtien
                // })
            }
        }).catch(err => { })
    }
    const handleChangePage = ele => {
        // setCurrentPage(ele)
        // // getData(ele)
        // getData2(ele)
        props.history.push(`/thong-ke/${ketqua.tungay},${ketqua.denngay},${ele}`)
    }
    // const fetchDropdownOptions = (key, list) => {
    //   const kq = filter(list, (item) => item.dangky.hoten.toLowerCase().indexOf(key.trim())>-1 || removeVietnameseTones(item.dangky.hoten.toLowerCase()).indexOf(removeVietnameseTones(key.trim()))>-1 || item.sodienthoai.indexOf(key.trim())>-1 || item.dangky.cmnd.indexOf(key.trim())>-1)
    //   setKetqua(kq)
    // }
    // const debounceDropDown = useCallback(debounce((nextValue, list) => fetchDropdownOptions(nextValue, list), 500), [])
    // const handleTimkiem = e => {
    //   setTimkiem(e.currentTarget.value)
    //   debounceDropDown(e.currentTarget.value.toLowerCase(), list)
    //   if (e.currentTarget.value.trim()) {
    //     setSearching(true)
    //   } else {
    //     setSearching(false)
    //   }
    // }
    // const getData2 = page => {
    //     axios.post(`danhsachthongke?page=${page}`, thongtin).then(response => {
    //         if (!unmounted.current) {
    //             setTotPages(response.data.last_page)
    //             setDanhsach(response.data.data)
    //             setKetqua({...ketqua,
    //                 total: response.data.total,
    //                 currentPage: response.data.current_page,
    //                 from: response.data.from,
    //                 to: response.data.to,
    //                 tungay: thongtin.tungay,
    //                 denngay: thongtin.denngay,
    //                 tongtien: response.tongtien
    //             })
    //         }
    //     }).catch(err => {})
    // }
    const handleSubmit = e => {
        e.preventDefault()
        props.history.replace(`/thong-ke/${thongtin.tungay},${thongtin.denngay},1`)
        // getData2(1)
    }
    const handleInputChange = e => {
        setThongtin({ ...thongtin, [e.currentTarget.name]: e.currentTarget.value })
    }
    const handleDownload = e => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "thong-ke.xlsx"
        axios.post(`thong-ke`, ketqua, { responseType: "blob" }).then(response => {
            // console.log(response);
            link.href = URL.createObjectURL(
                new Blob([response])
            );
            link.click();
        }).catch(err => { })
    }
    return <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="12" title="Thống kê xét nghiệm" className="text-md-center" />
        </Row>

        <Row>
            <Col>
                <Card small className="mb-4">
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col lg="3" className="form-group">
                                    <label>Từ ngày</label>
                                    <FormInput
                                        type="date"
                                        required
                                        name="tungay"
                                        value={thongtin.tungay}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label>Đến ngày</label>
                                    <FormInput
                                        type="date"
                                        required
                                        name="denngay"
                                        value={thongtin.denngay}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col lg="2" className="align-items-end d-flex form-group">
                                    <Button type="submit" theme="primary" block size="md">OK</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>

        {danhsach ? <Row>
            <Col>
                <Card small className="mb-4">
                    {ketqua.total ? <CardHeader className="font-weight-bold pb-0 align-items-center justify-content-between d-flex">
                        {/* <span>Showing {ketqua.from} to {ketqua.to} of {ketqua.total} entries</span> */}
                        <span>Total: {ketqua.total} entries</span>
                        <Button size="sm" onClick={handleDownload}>Download</Button>
                    </CardHeader> : null}
                    <CardBody>
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            #
                                        </th>
                                        {/* <th scope="col" className="border-0">
                                            Mã phiếu
                                        </th> */}
                                        <th scope="col" className="border-0">
                                            Họ tên
                                        </th>
                                        <th scope="col" className="border-0">
                                            Giới tính
                                        </th>
                                        <th scope="col" className="border-0">
                                            Ngày sinh
                                        </th>
                                        {/* <th scope="col" className="border-0">
                                            CMND
                                        </th> */}
                                        {/* <th scope="col" className="border-0">
                        Đ/c
                      </th> */}
                                        <th scope="col" className="border-0">
                                            Ngày kết quả
                                        </th>
                                        <th scope="col" className="border-0">
                                            Kết quả
                                        </th>
                                        {/* <th scope="col" className="border-0 text-center">
                                            Lần in
                                        </th> */}
                                        {/* <th scope="col" className="border-0"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {danhsach ? (danhsach.length ? danhsach.map((item, index) => <tr key={index}>
                                        <td className="align-middle">{index + 1}</td>
                                        {/* <td className="align-middle font-weight-bold">{item.maphieu}</td> */}
                                        <td className="align-middle font-weight-bold text-nowrap text-capitalize">
                                            {/* <NavLink to={`/chi-tiet/${item.id}`}><strong>{item.hoten}</strong></NavLink><p className="mb-0">SĐT: {item.sdt}</p> */}
                                            <b>{item.hoten}</b>{item.sdt&&<p className="mb-0">SĐT: {item.sdt}</p>}
                                        </td>
                                        <td className="align-middle">{item.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                                        <td className="align-middle">{item.ngaysinh}</td>
                                        {/* <td className="align-middle">{item.cmnd}</td> */}
                                        {/* <td className="align-middle">{item.dangky.diachi}</td> */}
                                        <td className="align-middle">{dateFormat(item.ngayketqua, 'HH:MM, dd/mm/yyyy')}</td>
                                        <td className={classNames("align-middle font-weight-bold", item.ketqua == 0 ? 'text-success' : 'text-danger')}>{item.ketqua == 0 ? 'Âm tính' : (item.ketqua == 1 ? 'Dương tính' : '')}</td>
                                        {/* <td className="align-middle text-center">{item}</td> */}
                                        {/* <td><NavLink to={`/chi-tiet/${item.dangky.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td> */}
                                    </tr>) : <tr><td className="text-center" colSpan="10">No records</td></tr>) : <tr><td className="text-center" colSpan="10"><Spinner animation="border" role="status" /></td></tr>}
                                    <tr>
                                        <td colSpan="10" className="font-weight-bold text-right">Tổng cộng: {currencyFormat(ketqua.tongtien)} VNĐ</td>
                                    </tr>
                                </tbody>
                                {danhsach && totPages > 1 ? <tfoot>
                                    <tr>
                                        <td colSpan="10">
                                            <MyPagination totPages={totPages} currentPage={currentPage} pageClicked={handleChangePage} />
                                        </td>
                                    </tr>
                                </tfoot> : null}
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row> : null}
    </Container>
};

export default Thongke;
