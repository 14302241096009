import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { renderRoutes } from 'react-router-config';
import Pusher from 'pusher-js'

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import { NavLink } from "react-router-dom";
import Thongbao from "../components/layout/Thongbao";

const DefaultLayout = ({ children, noNavbar, noFooter, route }) => {
  const [listToast, setToasts] = useState([])
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
    channel.bind('ketqua', data => {
      setToasts([...listToast, {
        id: data.id,
        name: 'Kết quả',
        desc: <React.Fragment>Xét nghiệm của "{data.hoten}" đã có kết quả. <NavLink to={`/chi-tiet/${data.id}`}>Chi tiết</NavLink></React.Fragment>,
        ngay: '',
        thoigian: 10000
      }])
    });
    // const url = 'https://www.freesoundslibrary.com/wp-content/uploads/2018/02/ding-dong-sound-effect.mp3'
    // const audio = new Audio(url);
    // audio.addEventListener('canplaythrough', (event) => {
    //   // the audio is now playable; play it if permissions allow
    //   audio.play();
    // });
    // (new Audio('https://www.freesoundslibrary.com/wp-content/uploads/2018/02/ding-dong-sound-effect.mp3')).play()
    return () => { pusher.disconnect() }
  }, [])
  const toastClose = e => {
    // console.log(e);
  }
  return <React.Fragment>
    <div
      style={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 1
      }}
    >
      {listToast.map((item, index) => <Thongbao key={index} onClose={toastClose} item={item} />)}
    </div>
    <Container fluid>
      <Row>
        <MainSidebar />
        <Col
          className="main-content p-0"
          lg={{ size: 10, offset: 2 }}
          md={{ size: 9, offset: 3 }}
          sm="12"
          tag="main"
        >
          {!noNavbar && <MainNavbar />}
          {/* {children} */}
          {renderRoutes(route.routes)}
          {!noFooter && <MainFooter />}
        </Col>
      </Row>
    </Container>
  </React.Fragment>
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: true
};

export default DefaultLayout;
