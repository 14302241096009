import React from "react";
import {
  Card,
  CardHeader
} from "shards-react";
import dateFormat from "dateformat";

const UserDetails = ({ userDetails }) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <h4 className="mb-0 text-capitalize">{userDetails.hoten}</h4>
      <span className="text-muted d-block mt-2 mb-2"><strong>Giới tính:</strong> {userDetails.gioitinh==1?'Nam':'Nữ'}</span>
      <span className="text-muted d-block mt-2 mb-2"><strong>Ngày sinh:</strong> {dateFormat(userDetails.ngaysinh, 'dd/mm/yyyy')}</span>
      <span className="text-muted d-block mt-2 mb-2"><strong>CMND:</strong> {userDetails.cmnd}</span>
      <span className="text-muted d-block mt-2 mb-2"><strong>Địa chỉ:</strong> {userDetails.diachi}</span>
      <span className="text-muted d-block mb-2"><strong>Điện thoại:</strong> {userDetails.sodienthoai || userDetails.sodienthoai}</span>
    </CardHeader>
  </Card>
);

export default UserDetails;
