export default function() {
  return [
    // {
    //   title: "Dashboard",
    //   to: "/overview",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   htmlAfter: ""
    // },
    {
      title: "Đăng ký",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/dang-ky",
    },
    {
      title: "Đang chờ",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/danh-sach-hom-nay",
    },
    {
      title: "Tạm thời",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/danh-sach-luu-tam",
    },
    {
      title: "Đang lấy mẫu",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/danh-sach-lay-mau",
    },
    {
      title: "Chờ kết quả",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/danh-sach-cho-ket-qua",
    },
    {
      title: "Xét nghiệm Công ty",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/xet-nghiem-cong-ty",
    },
    {
      title: "Kết quả",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/ket-qua",
    },
    {
      title: "Thống kê",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/thong-ke",
    },
    {
      title: "Khai báo y tế",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/khai-bao",
    },
    {
      title: "Người dùng",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/nguoi-dung",
    },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
