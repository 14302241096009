import React, { useEffect, useState, useRef } from "react";
import { Spinner } from 'react-bootstrap'
import { Container, Row, Col, Button } from "shards-react";
import { connect } from "react-redux";
import { SESSION_SCOPE } from '../actions/sessionActions';

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import Khaibao from "../components/user-profile-lite/Khaibao";

const ChitietKhaibao = (props) => {
  const unmounted = useRef(false)
  const [dulieu, setDulieu] = useState()
  const [loading, setLoading] = useState(true)
  const [khaibao, setKhaibao] = useState()
  useEffect(() => {
    const id = props.match.params.id
    getData(id)
    return () => { unmounted.current = true }
  }, [])
  const getData = (id) => {
    axios.get(`chitietkhaibao/${id}`).then(response => {
      if (!unmounted.current) {
        setLoading(false)
        if (response.data) {
          setKhaibao(response.data.khaibao)
          setDulieu(response.data)
        } else {
          props.history.replace('/khai-bao')
        }
      }
    }).catch(err => {
      props.history.replace('/khai-bao')
    })
  }
  const handleDelete = e => {
    if (!loading) {
      if (window.confirm('Bạn có chắc chắn muốn xoá không? Dữ liệu này sẽ không khôi phục nếu xoá.')) {
        setLoading(true)
        axios.post(`xoakhaibao`, dulieu).then(response => {
          setLoading(false)
          if (response.data) {
            props.history.goBack()
          } else {
            window.alert(response.error)
          }
        }).catch(err => {
          setLoading(false)
          window.alert(err)
        })
      }
    }
  }
  return <React.Fragment>
    <Container fluid className="main-content-container px-4">
      {loading ? <div className="loadingBox"><Spinner animation="border" role="status" /></div> : null}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Chi tiết khai báo" md="12" className="text-md-center" />
      </Row>
      {dulieu ? <React.Fragment>
        <Row>
          <Col lg="12" className="mb-4 text-right">
            <Button type="button" onClick={e=>props.history.goBack()}>Trở về</Button>
            {props.auth[SESSION_SCOPE].user.user.role == 'admin' ? <Button type="button" onClick={handleDelete} className="ml-3" theme="danger">Xoá</Button> : null}
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <UserDetails userDetails={dulieu} />
          </Col>
          <Col lg="6">
            <Khaibao khaibao={khaibao} />
          </Col>
        </Row>
      </React.Fragment> : null}
    </Container>
  </React.Fragment>
};

const mapStateToProps = state => {
  return {
    auth: state
  };
};
export default connect(mapStateToProps)(ChitietKhaibao);
