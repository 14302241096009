import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";
import getSidebarNavItemsThungan from "../data/sidebar-nav-items_thungan";
import getSidebarNavItemsLab from "../data/sidebar-nav-items_lab";
import Cookies from 'js-cookie';

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems()
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    const todo = JSON.parse(Cookies.get('user'))
    if (todo) {
      if (todo.loggedIn) {
        if (todo.user.user.role === 'thungan') {
          //thungan
          return getSidebarNavItemsThungan()
        } else if (todo.user.user.role === 'lab') {
          //lab
          return getSidebarNavItemsLab()
        } else if (todo.user.user.role === 'admin') {
          //admin
          return _store.navItems
        } else {
          return []
        }
      } else {
        return []
      }
    } else {
      return []
    }
    // return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
