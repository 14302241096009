import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody, Button } from "shards-react";
// import Pusher from 'pusher-js'

import axios from '../utils/axios';
import PageTitle from "../components/common/PageTitle";
import { Spinner } from "react-bootstrap";

const DanhsachLaymau = () => {
  const unmounted = useRef(false)
  const [danhsach, setDanhsach] = useState()
  // useEffect(() => {
  //   const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  //     cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  //     encrypted: true
  //   });
  //   const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
  //   channel.bind('khaibao', data => {
  //     getData()
  //   });
  //   return () => { pusher.disconnect() }
  // }, [])
  useEffect(() => {
    getData()
    return () => { unmounted.current = true }
  }, [])
  const getData = () => {
    axios.get(`danhsachdanglaymau`).then(response => {
      if (!unmounted.current) {
        setDanhsach(response.data)
      }
    })
  }
  return <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="12" title="Danh sách đang lấy mẫu" className="text-md-center" />
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Họ tên
                    </th>
                    <th scope="col" className="border-0">
                      Giới tính
                    </th>
                    <th scope="col" className="border-0">
                      Ngày sinh
                    </th>
                    <th scope="col" className="border-0">
                      CMND
                    </th>
                    <th scope="col" className="border-0">
                      Điện thoại
                    </th>
                    <th scope="col" className="border-0">
                      Ngày đăng ký
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {danhsach ? (danhsach.length ? danhsach.map((item, index) => <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle font-weight-bold text-nowrap text-capitalize"><NavLink to={`/chi-tiet/${item.id}`}><strong>{item.hoten}</strong></NavLink><p className="mb-0">Mã: {item.mamau}</p></td>
                    <td className="align-middle">{item.gioitinh == 1 ? 'Nam' : 'Nữ'}</td>
                    <td className="align-middle">{dateFormat(item.ngaysinh, 'dd/mm/yyyy')}</td>
                    <td className="align-middle">{item.cmnd}</td>
                    <td className="align-middle">{item.sodienthoai}</td>
                    <td className="align-middle">{dateFormat(item.created_at, 'HH:MM, dd/mm/yyyy')}</td>
                    <td><NavLink to={`/chi-tiet/${item.id}`} className="btn btn-primary btn-block">Chi tiết</NavLink></td>
                  </tr>) : <tr><td className="text-center" colSpan="8">No records</td></tr>) : <tr><td className="text-center" colSpan="8"><Spinner animation="border" role="status" /></td></tr>}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
};

export default DanhsachLaymau;
