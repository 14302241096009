import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as StoreProvider } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import { configureStore } from './store';
import routes from "./routes";
import RequireAuthentication from './components/RequireAuthentication';
// import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./assets/custom.css"
import "./assets/paper.css"

const store = configureStore();

export default () => (
  <StoreProvider store={store}>
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      <RequireAuthentication routes={routes}>
      {renderRoutes(routes)}
      {/* {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={props => <route.layout {...props}>
              <route.component {...props} />
            </route.layout>}
          />
        );
      })} */}
      </RequireAuthentication>
    </div>
  </Router>
  </StoreProvider>
);
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
